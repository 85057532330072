import React from 'react'
import { CSSTransition } from 'react-transition-group'

const Menu = ({ in: inProp, duration = 300, width = '100%', children }) => {
  const nodeRef = React.useRef(null)
  return (
    <>
      <style jsx>
        {`
          .content {
            position: absolute;
            background: var(--bs-light);
            z-index: 910;
            top: 0;
            bottom: 0;
            right: 0;
            border-top: solid 0.15rem var(--bs-primary);
            border-bottom: solid 0.15rem var(--bs-primary);
            border-right: solid 0.15rem var(--bs-primary);
            border-top-right-radius: var(--bs-border-radius);
            box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
            clip-path: inset(0px 0px 0px -15px);
            width: ${width};
          }

          .content > div {
            padding: 1rem 1rem;
          }

          .menu-enter {
            width: 0;
          }
          .menu-enter div {
            visibility: hidden;
          }
          .menu-enter-active {
            width: ${width};
            transition: width ${duration}ms ease-in-out;
          }
          .menu-enter-done div {
            visibility: visible;
          }
          .menu-exit-active {
            width: 0;
            transition: width ${duration}ms ease-in-out;
          }
        `}
      </style>

      <CSSTransition nodeRef={nodeRef} in={inProp} timeout={duration} classNames="menu" unmountOnExit>
        {state => {
          return (
            <div ref={nodeRef} className="d-flex flex-column content">
              <div className="flex-fill d-flex flex-column">{state === 'entered' && children}</div>
            </div>
          )
        }}
      </CSSTransition>
    </>
  )
}

export default Menu
