import React from 'react'
import withRouter from 'utils/withRouter.js'
import NavBar from 'components/common/navbar'
import Planner from './planner'

class Trainings extends React.Component {
  render() {
    let { pathname } = this.props.router.location
    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Allenamenti schedulati" />
        {pathname === '/trainings/list' && <Planner />}
      </div>
    )
  }
}

export default withRouter(Trainings)
