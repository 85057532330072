import * as React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'

const mapStateToProps = state => {
  return {
    visible: state.common.loading,
  }
}

class Loading extends React.Component {
  static defaultProps = {
    visible: true,
  }

  render() {
    return (
      this.props.visible === true && (
        <>
          <style jsx>
            {`
              .spinner-border {
                --bs-spinner-width: 5rem;
                --bs-spinner-height: 5rem;
                --bs-spinner-vertical-align: -0.125em;
                --bs-spinner-border-width: 0.5em;
                --bs-spinner-animation-speed: 0.75s;
                --bs-spinner-animation-name: spinner-border;
                border: var(--bs-spinner-border-width) solid currentcolor;
                border-right-color: transparent;
              }
            `}
          </style>
          <div
            style={{
              backgroundColor: 'lightgray',
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 1000000,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              opacity: 0.6,
            }}>
            <Spinner variant="success" />
          </div>
        </>
      )
    )
  }
}

export default connect(mapStateToProps)(Loading)
