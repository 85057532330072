import axios from 'axios'

const instance = axios.create()

instance.defaults.withCredentials = true

instance.interceptors.request.use(
  config => {
    config.headers.refererpath = window.location.pathname
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    return res
  },
  error => {
    if (error.response.status === 401) {
      window.location = '/login'
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default instance
