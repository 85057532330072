import * as React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import { LuCalendarClock, LuListRestart } from 'react-icons/lu'

class Cards extends React.Component {
  render() {
    return (
      <Row>
        <Col className="col-lg-4 col-md-12 col-sm-12">
          <Card className="bg-white">
            <Card.Body>
              <Card.Title className="d-flex">
                <Col className="col-5 ms-3">
                  <FaUsers size={50} color="lightblue" />
                </Col>
                <Col>
                  <Row style={{ color: '#ababab', fontSize: 18 }}>Utenti</Row>
                  <Row style={{ fontSize: 30 }}>200</Row>
                </Col>
              </Card.Title>
              <Link to={'/users'}>
                <Card.Footer>Vai alla lista</Card.Footer>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-lg-4 col-md-12 col-sm-12">
          <Card className="bg-white">
            <Card.Body>
              <Card.Title className="d-flex">
                <Col className="col-5 ms-3">
                  <LuListRestart size={50} color="lightsalmon" />
                </Col>
                <Col>
                  <Row style={{ color: '#ababab', fontSize: 18 }}>In scadenza</Row>
                  <Row style={{ fontSize: 30 }}>10</Row>
                </Col>
              </Card.Title>
              <Link to={'/abbonamenti/in_scadenza'}>
                <Card.Footer>Vai alla lista</Card.Footer>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-lg-4 col-md-12 col-sm-12">
          <Card className="bg-white">
            <Card.Body>
              <Card.Title className="d-flex">
                <Col className="col-5 ms-3">
                  <LuCalendarClock size={50} color="lightskyblue" />
                </Col>
                <Col>
                  <Row style={{ color: '#ababab', fontSize: 18 }}>Prenotazioni</Row>
                  <Row style={{ fontSize: 30 }}>20</Row>
                </Col>
              </Card.Title>
              <Link to={'/planning/list'}>
                <Card.Footer>Vai alla lista</Card.Footer>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Cards
