import React from 'react'
import withRouter from 'utils/withRouter.js'
import NavBar from 'components/common/navbar'
import ListaLuoghi from './list'

class Luoghi extends React.Component {
  render() {
    let { pathname } = this.props.router.location
    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Lista luoghi" />
        {pathname === '/places/list' && <ListaLuoghi />}
      </div>
    )
  }
}

export default withRouter(Luoghi)
