import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/payments'
import { updateErrors } from 'store/payments'
import { getMySubscriptions } from 'store/subscriptions'

const { t } = i18next

const initialState = {
  id: null,
  kind: 'Incasso',
  amount: 1,
  cause_id: 1,
  user_id: null,
  subscription_id: null,
  training_id: null,
  comment: '',
}

class ElementForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    createCause: () => {},
    onSave: () => {},
    errors: {},
    element: initialState,
    users: [],
    causes: [],
  }
  state = {
    element: initialState,
    element_hash: '',
    validated: false,
    cause_name: '',
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  componentDidMount() {
    this.setElement()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleChange = ({ target: { name, value } }) => {
    let element = { ...this.state.element }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    element[name] = value
    this.setState({ element }, () => this.updateHash())
  }
  handleSelectChange = name => async e => {
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    if (name === 'user_id') {
      await this.props.dispatch(getMySubscriptions(e.target.value))
    }
    this.setState({ element: { ...this.state.element, [name]: e.target.value } }, () => this.updateHash())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.element })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.element)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  openNewCause = () => this.setState({ show_new_cause: true })
  closeNewCause = () => this.setState({ show_new_cause: false })
  handleChangeCause = ({ target: { value } }) => {
    this.setState({ cause_name: value })
  }
  createNewCause = async () => {
    let cause = { id: null, name: this.state.cause_name, kind: this.state.element.kind }
    let cause_id = await this.props.createCause(cause)
    this.setState({ show_new_cause: false, element: { ...this.state.element, cause_id }, cause_name: '' })
  }

  render() {
    let { validated, element, cause_name } = this.state
    let { id, kind, amount, cause_id, user_id, subscription_id, comment } = element
    let { errors, causes, users, my_subscriptions } = this.props
    let title = id
      ? t('elements.element_form.edit_title', 'Modifica')
      : t('elements.element_form.create_title', 'Crea nuovo')

    return (
      <>
        <div className="flex-fill overflow-auto container">
          <h4 className="border-bottom text-primary p-1">{title}</h4>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Tipologia:</Form.Label>
                <span> *</span>
                <Form.Select
                  value={kind}
                  onChange={this.handleSelectChange('kind')}
                  isInvalid={validated && 'kind' in errors}>
                  <option key={`st-opt-incasso`} value="Incasso">
                    Incasso
                  </option>
                  <option key={`st-opt-spesa`} value="Spesa">
                    Spesa
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.kind}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Euro</Form.Label>
                <span> *</span>
                <Form.Control
                  name="amount"
                  value={amount}
                  type="number"
                  onChange={this.handleChange}
                  isInvalid={validated && 'amount' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Causale:</Form.Label>
                <span> *</span>
                <div className="d-flex">
                  <Form.Select
                    value={cause_id}
                    onChange={this.handleSelectChange('cause_id')}
                    isInvalid={validated && 'cause_id' in errors}>
                    {causes.map(c => (
                      <option key={`c-opt-${c.id}`} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.cause_id}</Form.Control.Feedback>
                  <Button className="ms-2" onClick={this.openNewCause}>
                    +
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Commento</Form.Label>
                <Form.Control
                  name="comment"
                  value={comment}
                  onChange={this.handleChange}
                  isInvalid={validated && 'comment' in errors}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row border mt-5">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Utente:</Form.Label>
                <div className="d-flex">
                  <Form.Select
                    value={user_id}
                    onChange={this.handleSelectChange('user_id')}
                    isInvalid={validated && 'user_id' in errors}>
                    <option key={`c-opt-null`} value={null}>
                      Nessun utente
                    </option>
                    {users.map(c => (
                      <option key={`c-opt-${c.id}`} value={c.id}>
                        {c.first_name} {c.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Abbonamento:</Form.Label>
                <div className="d-flex">
                  <Form.Select
                    value={subscription_id}
                    onChange={this.handleSelectChange('subscription_id')}
                    isInvalid={validated && 'subscription_id' in errors}>
                    <option key={`c-opt-null`} value={null}>
                      Nessun abbonamento
                    </option>
                    {my_subscriptions.map(c => (
                      <option key={`c-opt-${c.id}`} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
        <Modal backdrop="static" centered show={this.state.show_new_cause} onHide={this.closeNewCause}>
          <Modal.Header closeButton>
            <Modal.Title>Nuova causale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Tipologia:</Form.Label>
              <span> *</span>
              <Form.Select
                value={kind}
                disabled={true}
                onChange={this.handleSelectChange('kind')}
                isInvalid={validated && 'kind' in errors}>
                <option key={`st-opt-incasso`} value="Incasso">
                  Incasso
                </option>
                <option key={`st-opt-spesa`} value="Spesa">
                  Spesa
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.kind}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mt-3 mb-3">
              <Form.Label>Causale</Form.Label>
              <span> *</span>
              <Form.Control name="cause_name" value={cause_name} onChange={this.handleChangeCause} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeNewCause} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.createNewCause} variant="primary" disabled={!this.state.cause_name}>
              Aggiungi
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default ElementForm
