import React from 'react'
import { isValid, parse, format } from 'date-fns'
import { Button, Form, Alert, InputGroup } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

import validateFields from 'lib/validators/cicle'
import { updateErrors } from 'store/cicles'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  training_for_week: 1,
  duration: 4,
  start_at: '',
  end_at: '',
  price: '',
  recoveries: 0,
  enabled: true,
}

class CicleForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    element: initialState,
  }
  state = {
    element: initialState,
    element_hash: '',
    validated: false,
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  componentDidMount() {
    this.setElement()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleChange = ({ target: { name, value } }) => {
    let element = { ...this.state.element }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    element[name] = ['duration', 'training_for_week', ''].includes(name) ? parseInt(value) : value
    this.setState({ element }, () => this.updateHash())
  }
  handleDateChange = name => date => {
    let newDate = date !== null && isValid(date) ? format(date, 'yyyy-MM-dd') : ''
    this.setState({ element: { ...this.state.element, [name]: newDate } })
  }
  handleIsEnabled = name => e => {
    let element = { ...this.state.element }
    element[name] = e.target.checked
    this.setState({ element }, () => this.updateHash())
  }

  // isFormValid = () => {
  //   let { first_name, last_name, elementname, email, role, password, confirm_password } = this.state.element
  //   return (
  //     first_name !== '' &&
  //     last_name !== '' &&
  //     elementname !== '' &&
  //     role !== '' &&
  //     password !== '' &&
  //     confirm_password !== ''
  //   )
  // }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.element })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.element)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, element } = this.state
    let { id, name, training_for_week, duration, start_at, end_at, price, enabled, recoveries } = element
    let { errors } = this.props
    let title = id
      ? t('elements.element_form.edit_title', 'Modifica')
      : t('elements.element_form.create_title', 'Crea nuovo')

    let parsedStartAt = parse(start_at ?? '', 'yyyy-MM-dd', new Date())
    parsedStartAt = isValid(parsedStartAt) ? parsedStartAt : null
    let parsedEndAt = parse(end_at ?? '', 'yyyy-MM-dd', new Date())
    parsedEndAt = isValid(parsedEndAt) ? parsedEndAt : null

    return (
      <>
        <div className="flex-fill overflow-auto container">
          <h4 className="border-bottom text-primary p-1">{title}</h4>
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Nome</Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Numero recuperi</Form.Label>
                <span> *</span>
                <Form.Control
                  name="recoveries"
                  type="number"
                  value={recoveries}
                  onChange={this.handleChange}
                  isInvalid={validated && 'recoveries' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.recoveries}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Numero allenamenti per settimana</Form.Label>
                <span> *</span>
                <Form.Control
                  name="training_for_week"
                  type="number"
                  value={training_for_week}
                  onChange={this.handleChange}
                  isInvalid={validated && 'training_for_week' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.training_for_week}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Durata in mesi</Form.Label>
                <span> *</span>
                <Form.Control
                  name="duration"
                  type="number"
                  value={duration}
                  onChange={this.handleChange}
                  isInvalid={validated && 'duration' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.duration}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Inizio ciclo:</Form.Label>
                <div>
                  <DatePicker
                    style={{ lineHeight: 1.7 }}
                    className={`form-control ${validated && 'start_at' in errors ? 'border border-danger' : ''}`}
                    selected={parsedStartAt}
                    dateFormat="yyyy-MM-dd"
                    locale={localeIt}
                    onChange={this.handleDateChange('start_at')}
                    isClearable
                    showYearDropdown
                    yearDropdownItemNumber={20}
                    scrollableYearDropdown
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Fine ciclo:</Form.Label>
                <div>
                  <DatePicker
                    style={{ lineHeight: 1.7 }}
                    className={`form-control ${validated && 'end_at' in errors ? 'border border-danger' : ''}`}
                    selected={parsedEndAt}
                    dateFormat="yyyy-MM-dd"
                    locale={localeIt}
                    onChange={this.handleDateChange('end_at')}
                    isClearable
                    showYearDropdown
                    yearDropdownItemNumber={20}
                    scrollableYearDropdown
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Label className="mt-3 mb-2">Prezzo</Form.Label>
              <InputGroup>
                <Form.Control
                  name="price"
                  value={price}
                  type="number"
                  min={0}
                  step={0.01}
                  onChange={this.handleChange}
                  isInvalid={validated && 'price' in errors}
                />
                <InputGroup.Text id="basic-addon1">€</InputGroup.Text>

                <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
              </InputGroup>
            </div>
            <div className="col">
              <Form.Check
                className="mt-5"
                type="switch"
                id="enabled"
                name="enabled"
                label="Abilitato"
                checked={enabled}
                onChange={this.handleIsEnabled('enabled')}
              />
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default CicleForm
