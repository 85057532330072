import React from 'react'
import { ButtonGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import { addDays, subDays } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'

class DayPicker extends React.Component {
  static defaultProps = {
    date: new Date(),
  }

  onChangeDate = date => this.props.onChange(date)
  onNextDate = () => {
    let nextDate = addDays(this.props.date, 1)
    this.props.onChange(nextDate)
  }
  onPrevDate = () => {
    let prevDate = subDays(this.props.date, 1)
    this.props.onChange(prevDate)
  }

  render() {
    let { date } = this.props
    let selectedDate = new Date(date)

    return (
      <ButtonGroup className="d-flex align-items-center justify-content-center">
        <button className="btn btn-primary" onClick={this.onPrevDate}>
          <i className="fas fa-chevron-left " />
        </button>

        <DatePicker
          style={{ lineHeight: 1.7 }}
          className={`form-control`}
          selected={selectedDate}
          dateFormat="yyyy-MM-dd"
          locale={localeIt}
          onChange={this.onChangeDate}
          showYearDropdown
          yearDropdownItemNumber={20}
          scrollableYearDropdown
        />
        <button className={`btn btn-primary`} onClick={this.onNextDate}>
          <i className="fas fa-chevron-right " />
        </button>
      </ButtonGroup>
    )
  }
}

export default DayPicker
