import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import store from './store'
import Login from './components/login'
import Logout from './components/login/logout'
import Layout from './components/layout'
import Register from './components/login/register'
import NotificationManager from './components/system_wide/notification-manager'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/css/global.scss'

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <NotificationManager />
        <BrowserRouter basename="/">
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/register" element={<Register />} />
            <Route path="*" element={<Layout />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default withTranslation()(App)
