import React from 'react'
import { ButtonGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import { format, addDays, subDays, startOfWeek, endOfWeek } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'

class WeekPicker extends React.Component {
  static defaultProps = {
    date: new Date(),
  }

  onChangeDate = date => this.props.onChange(date)
  onNextDate = () => {
    let nextDate = startOfWeek(addDays(this.props.date, 7), { weekStartsOn: 1 })
    this.props.onChange(nextDate)
  }
  onPrevDate = () => {
    let prevDate = startOfWeek(subDays(this.props.date, 7), { weekStartsOn: 1 })
    this.props.onChange(prevDate)
  }

  render() {
    let { date } = this.props
    let selectedDate = new Date(date)
    let startDate = startOfWeek(date, { weekStartsOn: 1 })
    let endDate = endOfWeek(date, { weekStartsOn: 1 })

    return (
      <ButtonGroup className="d-flex align-items-center justify-content-center">
        <button className="btn btn-primary" onClick={this.onPrevDate}>
          <i className="fas fa-chevron-left " />
        </button>

        <DatePicker
          startDate={startDate}
          endDate={endDate}
          selected={selectedDate}
          className={`form-control`}
          dateFormat={'yyyy-MM-dd'}
          locale={localeIt}
          onChange={this.onChangeDate}
          customInput={<button>{`${format(startDate, 'yyyy-MM-dd')} - ${format(endDate, 'yyyy-MM-dd')}`}</button>}
          popperPlacement="bottom-end"
        />
        <button className={`btn btn-primary`} onClick={this.onNextDate}>
          <i className="fas fa-chevron-right " />
        </button>
      </ButtonGroup>
    )
  }
}

export default WeekPicker
