import React from 'react'
import { isValid, parse, format } from 'date-fns'
import { Button, Form, Alert } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

import validateFields from 'lib/validators/recovery_requests'
import { updateErrors } from 'store/recovery_requests'

const { t } = i18next

const initialState = {
  id: null,
  subscription_id: null,
  user_id: null,
  approved: true,
}

class RecoveryRequestForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    element: initialState,
    athletes: [],
    cicles: [],
  }
  state = {
    element: initialState,
    element_hash: '',
    validated: false,
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  componentDidMount() {
    this.setElement()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleChange = ({ target: { name, value } }) => {
    let element = { ...this.state.element }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    element[name] = value
    this.setState({ element }, () => this.updateHash())
  }
  handleSelectChange = name => e => {
    let errors = { ...this.props.errors }
    let element = { ...this.state.element }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    if (name === 'cicle_id') {
      element['price'] = this.props.cicles.find(el => el.id === parseInt(e.target.value)).price
      element['recoveries'] = this.props.cicles.find(el => el.id === parseInt(e.target.value)).recoveries
    }
    this.setState({ element: { ...element, [name]: e.target.value } }, () => this.updateHash())
  }
  handleDateChange = name => date => {
    let errors = { ...this.props.errors }
    let newDate = date !== null && isValid(date) ? format(date, 'yyyy-MM-dd') : ''
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ element: { ...this.state.element, [name]: newDate } })
  }
  handleIsEnabled = name => e => {
    let element = { ...this.state.element }
    element[name] = e.target.checked
    this.setState({ element }, () => this.updateHash())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.element })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.element)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, element } = this.state
    let { id, cicle_id, user_id, start_at, price, kind, enabled, recoveries } = element
    let { errors, athletes, cicles } = this.props
    let title = id
      ? t('elements.element_form.edit_title', 'Modifica')
      : t('elements.element_form.create_title', 'Crea nuovo')

    let parsedStartAt = parse(start_at ?? '', 'yyyy-MM-dd', new Date())
    parsedStartAt = isValid(parsedStartAt) ? parsedStartAt : null

    return (
      <>
        <div className="flex-fill overflow-auto container">
          <h4 className="border-bottom text-primary p-1">{title}</h4>
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Utente:</Form.Label>
                <Form.Select
                  value={user_id}
                  onChange={this.handleSelectChange('user_id')}
                  isInvalid={validated && 'user_id' in errors}>
                  <option value="">Nessuna selezione</option>
                  {athletes.map(el => {
                    return (
                      <option key={`rol-opt-${el.id}`} value={el.id}>
                        {el.first_name} {el.last_name}
                      </option>
                    )
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.user_id}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Check
                className="mt-5"
                type="switch"
                id="enabled"
                name="enabled"
                label="Abilitato"
                checked={enabled}
                onChange={this.handleIsEnabled('enabled')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Tipo abbonamento:</Form.Label>
                <Form.Select
                  value={cicle_id}
                  onChange={this.handleSelectChange('cicle_id')}
                  isInvalid={validated && 'cicle_id' in errors}>
                  <option value="0">Nessuna selezione</option>
                  {cicles.map(el => {
                    return (
                      <option key={`rol-opt-${el.id}`} value={el.id}>
                        {el.name}
                      </option>
                    )
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.cicle_id}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Tipo pagamento:</Form.Label>
                <Form.Select
                  value={kind}
                  onChange={this.handleSelectChange('kind')}
                  isInvalid={validated && 'kind' in errors}>
                  <option key="p-1" value="Unica soluzione">
                    Unica soluzione
                  </option>
                  <option key="p-2" value="3 rate">
                    3 rate
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.kind}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Recuperi:</Form.Label>
                <Form.Control
                  name="recoveries"
                  value={recoveries}
                  type="number"
                  min={0}
                  step={0.01}
                  onChange={this.handleChange}
                  isInvalid={validated && 'recoveries' in errors}
                  disabled={id === null}
                />
                {id === null && (
                  <div style={{ fontSize: 12, marginTop: 3 }}>
                    <b>NB:</b> Aggiornabile solo una volta creata la sottoscrizione!
                  </div>
                )}
                <Form.Control.Feedback type="invalid">{errors.recoveries}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Prezzo</Form.Label>
                <Form.Control
                  name="price"
                  value={price}
                  type="number"
                  min={0}
                  step={0.01}
                  onChange={this.handleChange}
                  isInvalid={validated && 'price' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Data sottoscrizione:</Form.Label>
                <div>
                  <DatePicker
                    style={{ lineHeight: 1.7 }}
                    className={`form-control ${validated && 'start_at' in errors ? 'border border-danger' : ''}`}
                    selected={parsedStartAt}
                    dateFormat="yyyy-MM-dd"
                    locale={localeIt}
                    onChange={this.handleDateChange('start_at')}
                    isClearable
                    showYearDropdown
                    yearDropdownItemNumber={20}
                    scrollableYearDropdown
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default RecoveryRequestForm
