import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

const initialState = {
  elements: [],
  total: 0,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_element: {},
  errors: {},
  my_payments: [],
  current_page: 1,
}

export const getElements = createAsyncThunk('payments/get_payments', async (params, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/payments?`
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().payments
    let current_page = parseInt(offset + 1 / limit) + 1 || 1
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { elements: response.data, total, current_page }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const getMyPayments = createAsyncThunk('payments/get_my_payments', async (subscription_id, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/subscriptions/${subscription_id}/payments?`
    let response = await axios({
      url,
      method: 'GET',
    })
    return { my_payments: response.data }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createElement = createAsyncThunk('payments/create_payment', async (user, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/payments`,
      method: 'POST',
      data: user,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { user: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile effettuare il salvataggio!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateElement = createAsyncThunk('payments/update_payment', async (element, thunkApi) => {
  try {
    if (!element.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/payments/${element.id}`,
      method: 'PUT',
      data: element,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { user: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile effettuare il salvataggio!'))
    return thunkApi.rejectWithValue({})
  }
})

export const deleteElement = createAsyncThunk('payments/delete_payment', async (element_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/payments/${element_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare!'))
    return thunkApi.rejectWithValue()
  }
})
export const toggleElement = createAsyncThunk('payments/toggle', async (element_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/payments/${element_id}/toggle`,
      method: 'GET',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento modificato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification("Impossibile modificare l'elemento"))
    return thunkApi.rejectWithValue()
  }
})

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    // filterRoles: (state, action) => {
    //   state.filtered_roles = action.payload
    // },
    // filterLevels: (state, action) => {
    //   state.filtered_levels = action.payload
    // },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    resetCurrentElement: state => {
      state.current_element = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentElement: (state, action) => {
      state.current_element = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getElements.fulfilled, (state, action) => {
        let { elements, total, current_page } = action.payload
        state.elements = current_page === 1 ? elements : [...state.elements, ...elements]
        state.current_page = current_page
        state.total = total
        state.loading = false
      })
      .addCase(getElements.rejected, state => {
        state.users = []
        state.total = 0
        state.current_page = 1
        state.loading = false
      })
      .addCase(getMyPayments.fulfilled, (state, action) => {
        let { my_payments } = action.payload
        state.my_payments = my_payments
        state.loading = false
      })
      .addCase(getMyPayments.rejected, state => {
        state.my_payments = []
        state.loading = false
      })
      .addCase(updateElement.rejected, () => {
        throw new Error()
      })
      .addCase(createElement.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentElement,
  toggleShowForm,
  updateErrors,
  setCurrentElement,
} = paymentsSlice.actions
export default paymentsSlice.reducer
