import React from 'react'
import { Trans } from 'react-i18next'

import styles from './responsive_table.module.css'

const RefreshingMask = React.memo(({ isRefreshing }) => (
  <div className={`${styles.mask} ${isRefreshing ? 'd-flex justify-content-center align-items-center' : 'd-none'}`}>
    <div className="px-4 py-2 bg-light border">
      <b>
        <Trans i18nKey="common.responsive_table.refreshing">Reloading...</Trans>
      </b>
    </div>
  </div>
))
RefreshingMask.displayName = 'RefreshingMask'

export default RefreshingMask
