import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import jsPDF from 'jspdf'

import {
  toggleShowForm,
  resetCurrentElement,
  setCurrentElement,
  getElements,
  updateElement,
  createElement,
  deleteElement,
} from '../../store/payments'
import { getElements as getCauses, createElement as createCause } from '../../store/causes'
import { getUsers } from '../../store/users'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import ElementForm from './form'

const mstp = state => {
  let { elements, total, show_form, current_element, errors } = state.payments
  let { elements: causes } = state.causes
  let { users } = state.users
  let { my_subscriptions } = state.subscriptions
  return {
    elements,
    causes,
    users,
    my_subscriptions,
    total,
    show_form,
    current_element,
    errors,
  }
}

class PaymentsList extends React.Component {
  static defaultProps = {
    elements: [],
    causes: [],
    users: [],
    my_subscriptions: [],
    getData: () => {},
    columns: [],
    filter: null,
    show_disabled: false,
  }

  state = {
    show_modal_toggle: false,
    show_modal_delete: false,
    element_to_delete: { id: '', name: '' },
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  printElement = data => {
    const pdf = new jsPDF('p', 'pt', 'a4')
    pdf.text(235, 40, 'BVO - Ricevuta')
    pdf.line(30, 50, 560, 50) // horizontal line
    pdf.text('Ricevuta numero: ' + data.id, 50, 80)
    pdf.text('Nome: ' + data.user, 50, 120)
    pdf.text('Codice fiscale: ' + data.fiscal_code, 50, 140)
    pdf.text('Causale: ' + data.cause, 50, 180)
    pdf.text('Data: ' + data.created_at.split('T')[0], 50, 200)
    pdf.text('Ammontare: ' + data.amount + ' €', 50, 240)
    pdf.line(30, 260, 560, 260) // horizontal line
    pdf.setLineWidth(400)
    pdf.save('ricevuta-n' + data.id)
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        this.props.dispatch(setCurrentElement(rowData))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-print',
      text: 'Stampa',
      onClick: rowData => {
        this.printElement(rowData)
      },
    },
    {
      text: '---',
    },
    {
      icon: 'fa-trash',
      text: 'Elimina',
      onClick: ({ id, name }) => {
        this.setState({
          show_modal_delete: true,
          element_to_delete: { id, name },
        })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'ID',
      className: 'd-lg-block text-center',
      style: { width: '3%', minWidth: '2rem' },
      data: 'id',
    },
    {
      title: 'Tipologia',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'kind',
    },
    {
      title: 'Euro',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: false,
      data: ({ rowData }) => `${rowData.amount} €`,
    },
    {
      title: 'Utente',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: false,
      data: 'user',
    },
    {
      title: 'Causale',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: false,
      data: 'cause',
    },
    {
      title: 'Data',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: true,
      sortingKey: 'payments.created_at',
      data: ({ rowData }) => `${rowData.created_at.split('T')[0]}`,
    },
  ]

  async componentDidMount() {
    await this.props.dispatch(getCauses({ offset: 0, limit: 1000, sortData: {}, filter: '' }))
    await this.props.dispatch(getUsers({ offset: 0, limit: 1000000, sortData: {}, filter: '' }))
    this.tableRef.current.refreshData()
  }

  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getElements({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentElement())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancelDelete = () => {
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => this.setState({ filter })

  saveElement = async element => {
    try {
      if (element.id !== null) {
        await this.props.dispatch(updateElement(element))
        this.tableRef.current.refreshData()
      } else {
        await this.props.dispatch(createElement(element))
        this.tableRef.current.refreshData()
      }
      return true
    } catch (err) {
      return false
    }
  }
  onConfirmDelete = async () => {
    let element = this.state.element_to_delete
    await this.props.dispatch(deleteElement(element.id))
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }

  createCause = async cause => {
    await this.props.dispatch(createCause(cause))
    await this.props.dispatch(getCauses({ offset: 0, limit: 1000, sortData: {}, filter: '' }))
    return this.props.causes[this.props.causes.length - 1].id
  }

  rowClasses = ({ kind }) => {
    if (kind === 'Incasso') return 'bg-success bg-gradient bg-opacity-10'
    if (kind === 'Spesa') return 'bg-warning bg-gradient bg-opacity-10'
    return ''
  }

  render() {
    let { elements, total, current_element, show_form, errors, causes, users, my_subscriptions } = this.props
    if (!elements) return null

    return (
      <div className="container-fluid flex-fill d-flex flex-column pt-3 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <Button onClick={this.onToggleForm} disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi incasso/spesa
                </Button>
              </div>
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={elements}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={ElementForm}
            menuContentProps={{
              element: current_element,
              closeForm: this.onToggleForm,
              onSave: this.saveElement,
              errors,
              causes,
              users,
              my_subscriptions,
              dispatch: this.props.dispatch,
              createCause: this.createCause,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina elemento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad eliminiare <b>{this.state.element_to_delete.name}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDelete} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(mstp)(PaymentsList)
