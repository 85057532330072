import React from 'react'
import axios from 'axios'
import config from 'config'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import logo from '../../assets/images/logo-banner.png'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'
import validateFields from 'lib/validators/user'

class Register extends React.Component {
  state = {
    role: 'Utente',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
    gender: 'Maschile',
    birthday_date: moment().format('YYYY-MM-DD').toString(),
    birthday_city: '',
    birthday_province: '',
    fiscal_code: '',
    telephone_number: '',
    register_ok: false,
    errors: {},
    validated: false,
    cicle_id: '',
    cicles: [],
    loading: false,
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    let errors = { ...this.state.errors }
    delete errors[name]
    if (name === 'first_name' || name === 'last_name') {
      let username =
        this.state.first_name.replace(/[\W_]+/g, '') + this.state.last_name.replace(/[\W_]+/g, '').toLowerCase()
      this.setState({ username })
    }
    this.setState({ [name]: value, errors })
  }
  onChangeDate = date => this.setState({ birthday_date: moment(date).format('YYYY-MM-DD').toString() })

  async componentDidMount() {
    let url = `${config.SERVER_API_URL}/cicles`
    let response = await axios({
      url,
      method: 'GET',
    })
    this.setState({ cicles: response.data })
  }

  onConfirm = () => {
    this.setState({ loading: true }, async () => {
      try {
        let errors = validateFields({ ...this.state })
        if (!this.state.password || !this.state.confirm_password) {
          errors['password'] = 'Password: campo richiesto'
          errors['confirm_password'] = 'Password: campo richiesto'
        }
        if (this.state.cicle_id === '') {
          errors['cicle_id'] = 'Seleziona un abbonamento'
        }
        if (Object.keys(errors).length) {
          this.setState({ errors, validated: true, loading: false })
        } else {
          let url = `${config.SERVER_API_URL}/users/register`
          let response = await axios({ url, method: 'POST', data: { ...this.state, cicles: [] } })
          let user = response.data
          url = `${config.SERVER_API_URL}/subscriptions`
          let price = this.state.cicles.find(el => el.id === parseInt(this.state.cicle_id))?.price ?? 0
          await axios({
            url,
            method: 'POST',
            data: {
              cicle_id: this.state.cicle_id,
              user_id: user.id,
              start_at: moment().format('YYYY-MM-DD').toString(),
              price,
              kind: 'Unica soluzione',
              enabled: true,
            },
          })
          this.setState({ register_ok: true })
        }
      } catch (err) {
        let errors = {
          ...this.state.errors,
          server: "Impossibile effettuare la registrazione, contattare l'assistenza!",
        }
        this.setState({ errors, loading: false })
        console.log(err)
      }
    })
  }
  render() {
    let {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      gender,
      birthday_date,
      birthday_city,
      birthday_province,
      fiscal_code,
      telephone_number,
      register_ok,
      errors,
      validated,
      cicles,
      cicle_id,
      loading,
    } = this.state

    let selectedDate = new Date(birthday_date)
    return (
      <div className="d-flex justify-content-center align-items-center flex-column" style={{ marginTop: 20 }}>
        <Card style={{ backgroundColor: '#dee2e6' }} className="p-4 pb-1 mt-auto text-center container-md">
          <Card.Img
            variant="top"
            src={logo}
            className="align-self-center"
            style={{ height: 100, width: 100, marginBottom: 50 }}
          />
          {register_ok === true && (
            <div className="m-5">
              <h1 style={{ color: 'darkgreen' }}>Registrazione effettuata con successo!</h1>
            </div>
          )}
          {register_ok === false && (
            <>
              <h2>Effettua qui la tua registrazione</h2>
              <Card.Body className="m-auto mt-3 container row justify-content-center">
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      name="first_name"
                      type="text"
                      onChange={this.handleChange}
                      value={first_name}
                      isInvalid={validated && 'first_name' in errors}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Cognome</Form.Label>
                    <Form.Control
                      name="last_name"
                      type="text"
                      onChange={this.handleChange}
                      value={last_name}
                      isInvalid={validated && 'last_name' in errors}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      type="text"
                      onChange={this.handleChange}
                      value={email}
                      isInvalid={validated && 'email' in errors}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="text"
                      onChange={this.handleChange}
                      value={password}
                      isInvalid={validated && 'password' in errors}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Ripeti la password</Form.Label>
                    <Form.Control
                      name="confirm_password"
                      type="text"
                      onChange={this.handleChange}
                      value={confirm_password}
                      isInvalid={validated && 'confirm_password' in errors}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Tipo abbonamento da sottoscrivere</Form.Label>
                    <Form.Select
                      name="cicle_id"
                      value={cicle_id}
                      aria-label="Abbonamento"
                      onChange={this.handleChange}
                      isInvalid={validated && 'cicle_id' in errors}>
                      <option key={`rol-opt-null`} value={''}>
                        Nessun abbonamento selezionato
                      </option>
                      {cicles.map(el => {
                        return (
                          <option key={`rol-opt-${el.id}`} value={el.id}>
                            {el.name}
                          </option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Genere</Form.Label>
                    <Form.Select name="gender" value={gender} aria-label="Genere" onChange={this.handleChange}>
                      <option value="Maschile">Maschile</option>
                      <option value="Femminile">Femminile</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Luogo di nascita</Form.Label>
                    <Form.Control
                      name="birthday_city"
                      type="select"
                      onChange={this.handleChange}
                      value={birthday_city}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Provincia di nascita</Form.Label>
                    <Form.Control
                      name="birthday_province"
                      type="text"
                      onChange={this.handleChange}
                      value={birthday_province}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 justify-content-start d-flex flex-column">
                    <Form.Label>Data di nascita</Form.Label>
                    <DatePicker
                      style={{ lineHeight: 1.7 }}
                      className={`form-control`}
                      selected={selectedDate}
                      dateFormat="dd-MM-yyyy"
                      locale={localeIt}
                      onChange={this.onChangeDate}
                      showYearDropdown
                      yearDropdownItemNumber={20}
                      scrollableYearDropdown
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Codice fiscale</Form.Label>
                    <Form.Control name="fiscal_code" type="text" onChange={this.handleChange} value={fiscal_code} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      name="telephone_number"
                      type="text"
                      onChange={this.handleChange}
                      value={telephone_number}
                    />
                  </Form.Group>
                </div>
                <div className="row w-100 align-items-center justify-content-center">
                  {Object.keys(errors).includes('server') && <div style={{ color: 'red' }}>{errors['server']}</div>}
                  <Button
                    style={{ width: '10rem' }}
                    variant="warning"
                    className="rounded-3 mt-5 mb-2"
                    onClick={this.onConfirm}
                    disabled={loading}>
                    {loading === true && <Spinner animation="border" />}
                    {loading === false && 'Registrami'}
                  </Button>
                </div>
              </Card.Body>
            </>
          )}
        </Card>
      </div>
    )
  }
}

export default Register
