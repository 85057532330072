import * as React from 'react'
import onClickOutside from 'react-onclickoutside'
import { Calendar, DateObject } from 'react-multi-date-picker'
import { Button } from 'react-bootstrap'

import italian_it from './locales_it'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import './index.css'

const intervals = {
  // now: 'Oggi',
  last_month: 'Ultimo mese',
  prev_month: 'Mese precedente',
  last_year: "Quest'anno",
  prev_year: 'Anno precedente',
  custom: 'Intervallo personalizzato',
}
const format = 'YYYY-MM-DD'

const CalendarsPicker = ({ values, setValues }) => {
  return <Calendar locale={italian_it} format={format} value={values} onChange={setValues} range numberOfMonths={2} />
}

class DateFilter extends React.Component {
  state = {
    open: false,
    values: [],
    selected: '',
  }

  onClickOpen = () => this.setState({ open: !this.state.open })
  handleClickOutside = evt => {
    let { values, selected } = this.state
    if (values.length !== 2) {
      values = []
      selected = ''
    }
    this.setState({ open: false, values, selected })
  }
  setSelected = selected => () => {
    let values = []
    switch (selected) {
      case 'now': {
        let date = new DateObject()
        values = [date.format(format), date.format(format)]
        break
      }
      case 'last_month': {
        let date = new DateObject()
        let first_of_month = date.toFirstOfMonth().format(format)
        let last_of_month = date.toLastOfMonth().format(format)
        values = [first_of_month, last_of_month]
        break
      }
      case 'prev_month': {
        let date = new DateObject().subtract(1, 'month')
        let first_of_month = date.toFirstOfMonth().format(format)
        let last_of_month = date.toLastOfMonth().format(format)
        values = [first_of_month, last_of_month]
        break
      }
      case 'last_year': {
        let date = new DateObject()
        let first_of_month = date.toFirstOfYear().format(format)
        let last_of_month = date.toLastOfYear().format(format)
        values = [first_of_month, last_of_month]
        break
      }
      case 'prev_year': {
        let date = new DateObject().subtract(1, 'year')
        let first_of_month = date.toFirstOfYear().format(format)
        let last_of_month = date.toLastOfYear().format(format)
        values = [first_of_month, last_of_month]
        break
      }
      default:
    }
    this.setState({ selected, open: selected === 'custom', values }, () => {
      if (selected !== 'custom') this.updateFilter()
    })
  }
  resetFilter = () => {
    this.setState({ open: false, selected: '', values: [] }, () => this.updateFilter())
  }
  setValues = values => {
    values = values.map(d => d.toString())
    this.setState({ values })
  }

  updateFilter = () => {
    this.props.onSubmit(this.state.values)
    this.setState({ open: false })
  }

  render() {
    let { selected, values } = this.state
    return (
      <div className="date-filter">
        <div
          className={`date-filter-input ${this.state.open ? 'date-filter-input-focus' : ''}`}
          onClick={this.onClickOpen}>
          {selected === '' && <span className="text-muted">{this.props.placeholder ?? 'Filtra per data..'}</span>}
          {selected !== '' && selected !== 'custom' && (
            <div className="d-flex">
              <div>{intervals[selected]}</div>
              <i onClick={this.resetFilter} className="fa fa-close position-absolute search-icon clearable" />
            </div>
          )}
          {selected !== '' && selected === 'custom' && (
            <div className="d-flex">
              <div>{(values ?? []).map(v => v.toString()).join('-')}</div>
              <i onClick={this.resetFilter} className="fa fa-close position-absolute search-icon clearable" />
            </div>
          )}
        </div>
        {this.state.open === true && (
          <div className="date-filter-menu d-flex flex-direction-row">
            <div className="list">
              {/* <div */}
              {/*   className={`date-filter-menu-item ${selected === 'now' ? 'selected' : ''}`} */}
              {/*   onClick={this.setSelected('now')}> */}
              {/*   Oggi */}
              {/* </div> */}
              <div
                className={`date-filter-menu-item ${selected === 'last_month' ? 'selected' : ''}`}
                onClick={this.setSelected('last_month')}>
                Questo mese
              </div>
              <div
                className={`date-filter-menu-item ${selected === 'prev_month' ? 'selected' : ''}`}
                onClick={this.setSelected('prev_month')}>
                Mese precedente
              </div>
              <div
                className={`date-filter-menu-item ${selected === 'last_year' ? 'selected' : ''}`}
                onClick={this.setSelected('last_year')}>
                Quest'anno
              </div>
              <div
                className={`date-filter-menu-item ${selected === 'prev_year' ? 'selected' : ''}`}
                onClick={this.setSelected('prev_year')}>
                Anno precedente
              </div>
              <div
                className={`date-filter-menu-item ${selected === 'custom' ? 'selected' : ''}`}
                onClick={this.setSelected('custom')}>
                Personalizza intervallo
              </div>
            </div>
            {this.state.selected === 'custom' && (
              <div className="custom-filter ps-1">
                <CalendarsPicker values={values} setValues={this.setValues} />
                <div className="float-end p-1">
                  <Button size="sm" onClick={this.updateFilter} disabled={values.length !== 2}>
                    Applica
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default onClickOutside(DateFilter)
