import React from 'react'
import { Card, Table } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'
import styles from './dashboard.module.css'

class Tasks extends React.Component {
  render() {
    return (
      <>
        <Card className="mb-4">
          <Card.Header className="bg-light">
            <Card.Title>Abbonamenti in scadenza</Card.Title>
          </Card.Header>
          <Card.Body className="bg-white">
            <Table responsive bordered hover striped style={{ '--bs-table-bg': 'white' }}>
              <thead>
                <tr>
                  <th className={styles.tableTH}>Nome Cognome</th>
                  <th className={styles.tableTH}>Tipologia</th>
                  <th className={styles.tableTH}>Scadenza</th>
                  <th className={styles.tableTH}></th>
                </tr>
              </thead>
              <tbody>
                <tr className="fs-7 text-truncate">
                  <td>
                    <div className="fw-bold">Angelo D'Autilia</div>
                    <div className="">Numero tessera: 1010101</div>
                  </td>
                  <td>
                    <div className="bg-warning-subtle text-center p-2 shadow-sm">PRINCIPIANTE</div>
                  </td>
                  <td>
                    <div className="bg-success-subtle text-center p-2 shadow-sm">20/10/2024</div>
                  </td>
                  <td className="text-center">
                    <FaEye size={24} color="grey" className="mt-1" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header className="bg-light">
            <Card.Title>Prenotazioni</Card.Title>
            <Card.Subtitle className="fs-7 mb-2 text-muted">Lista prenotazione imminenti</Card.Subtitle>
          </Card.Header>
          <Card.Body className="bg-white">
            <Table responsive bordered hover striped style={{ '--bs-table-bg': 'white' }}>
              <thead>
                <tr>
                  <th className={styles.tableTH}>Nome</th>
                  <th className={styles.tableTH}>Campo</th>
                  <th className={styles.tableTH}>Giorno</th>
                  <th className={styles.tableTH}>Orario</th>
                  <th className={styles.tableTH}></th>
                </tr>
              </thead>
              <tbody>
                <tr className="fs-7 text-truncate">
                  <td>
                    <div className="fw-bold">Angelo D'Autilia</div>
                  </td>
                  <td>
                    <div className="bg-warning-subtle text-center p-2 shadow-sm">A1</div>
                  </td>
                  <td>
                    <div className="bg-primary-subtle text-center p-2 shadow-sm">Oggi</div>
                  </td>
                  <td>
                    <div className="bg-success-subtle text-center p-2 shadow-sm">10:30 - 11:30</div>
                  </td>
                  <td className="text-center">
                    <FaEye size={20} color="grey" className="mt-1" />
                  </td>
                </tr>
                <tr className="fs-7 text-truncate">
                  <td>
                    <div className="fw-bold">Angelo D'Autilia</div>
                  </td>
                  <td>
                    <div className="bg-warning-subtle text-center p-2 shadow-sm">B4</div>
                  </td>
                  <td>
                    <div className="bg-primary-subtle text-center p-2 shadow-sm">Domani</div>
                  </td>
                  <td>
                    <div className="bg-success-subtle text-center p-2 shadow-sm">12:30 - 13:30</div>
                  </td>
                  <td className="text-center">
                    <FaEye size={20} color="grey" className="mt-1" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default Tasks
