import React from 'react'
import { Form } from 'react-bootstrap'
import axios from '../../axios'
import config from '../../config'

class DocumentUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = { errors: '' }
  }

  uploadFile = async event => {
    try {
      event.preventDefault()
      let file = event.target.files[0]
      const url = `${config.SERVER_API_URL}/documents`
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', file.name)
      formData.append('user_id', this.props.user_id)
      const params = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      await axios.post(url, formData, params)
      this.props.onConfirmChange()
    } catch (err) {
      console.log(err.message)
      this.setState({ errors: err.message })
    }
  }

  render() {
    let { errors } = this.state
    return (
      <Form.Group className="mt-3 mb-3">
        <Form.Label>Aggiungi certificato</Form.Label>
        <Form.Control type="file" id="filereader" onChange={this.uploadFile} />
        {errors !== '' && <div className="mt-2 text-danger">{errors}</div>}
      </Form.Group>
    )
  }
}

export default DocumentUploader
