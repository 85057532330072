import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'
import { isValid, parse } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'

import {
  toggleShowForm,
  toggleDisabledFilter,
  resetCurrentElement,
  setCurrentElement,
  getElements,
  updateElement,
  createElement,
  deleteElement,
} from '../../store/cicles'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import ElementForm from '../cicles/form'
import CheckboxFilter from '../system_wide/checkbox_filter'

const mstp = state => {
  let { elements, total, show_disabled, show_form, current_element, errors, filtered_roles, filtered_levels } =
    state.cicles
  return {
    elements,
    total,
    show_disabled,
    show_form,
    current_element,
    errors,
    filtered_roles,
    filtered_levels,
  }
}

class CiclesList extends React.Component {
  static defaultProps = {
    elements: [],
    getData: () => {},
    columns: [],
    filter: null,
    show_disabled: false,
  }

  state = {
    show_modal_toggle: false,
    show_modal_delete: false,
    element_to_delete: { id: '', name: '' },
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        this.props.dispatch(setCurrentElement(rowData))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-trash',
      text: 'Elimina',
      onClick: ({ id, name }) => {
        this.setState({
          show_modal_delete: true,
          element_to_delete: { id, name },
        })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '20%', minWidth: '8rem' },
      sortable: true,
      data: 'name',
    },
    {
      title: 'Giorni alla settimana',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'training_for_week',
    },
    {
      title: 'Recuperi',
      className: 'd-lg-block flex-fill',
      style: { width: '10%', minWidth: '8rem' },
      sortable: true,
      data: 'recoveries',
    },
    {
      title: 'Durata (mesi)',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: true,
      data: 'duration',
    },
    {
      title: 'Data inizio',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: true,
      data: 'start_at',
    },
    {
      title: 'Data fine',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '10%', minWidth: '10rem' },
      sortable: true,
      data: 'end_at',
    },
    {
      title: 'Prezzo',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '5%', minWidth: '5rem' },
      sortable: true,
      data: ({ rowData }) => `${rowData.price} €`,
    },
  ]

  componentDidMount() {
    this.tableRef.current.refreshData()
  }
  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getElements({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentElement())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancelDelete = () => {
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => this.setState({ filter })
  onChangeFilterDisabled = () => {
    this.props.dispatch(toggleDisabledFilter())
    this.tableRef.current.refreshData()
  }

  saveElement = async element => {
    try {
      if (element.id !== null) {
        await this.props.dispatch(updateElement(element))
        this.tableRef.current.refreshData()
      } else {
        await this.props.dispatch(createElement(element))
        this.tableRef.current.refreshData()
      }
      return true
    } catch (err) {
      return false
    }
  }
  onConfirmDelete = async () => {
    let element = this.state.element_to_delete
    await this.props.dispatch(deleteElement(element.id))
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }

  rowClasses = ({ enabled }) => (enabled === 0 ? 'bg-warning bg-gradient bg-opacity-50' : '')

  render() {
    let { elements, total, current_element, show_form, show_disabled, errors } = this.props
    if (!elements) return null

    return (
      <div className="container-fluid flex-fill d-flex flex-column pt-3 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <Button onClick={this.onToggleForm} disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi Tipologia
                </Button>
              </div>
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
                <Form.Check
                  className="float-md-end mt-2"
                  type="switch"
                  id="deactivated-switch"
                  label="Visualizza disabilitati"
                  value={show_disabled}
                  onChange={this.onChangeFilterDisabled}
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={elements}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={ElementForm}
            menuContentProps={{
              element: current_element,
              closeForm: this.onToggleForm,
              onSave: this.saveElement,
              errors,
              dispatch: this.props.dispatch,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina elemento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad eliminiare <b>{this.state.element_to_delete.name}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDelete} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(mstp)(CiclesList)
