import React from 'react'
import { DebounceInput } from 'react-debounce-input'
import i18next from 'i18next'

const { t } = i18next

class SearchButton extends React.PureComponent {
  static defaultProps = {
    onChange: () => {},
    className: '',
    style: {},
    value: '',
  }
  state = { icon: 'search' }

  inputRef = null

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  onInput = e => {
    e.target.value = e.target.value.replace(/[()[\]]+/, '')
    let icon = e.target.value ? 'times' : 'search'
    this.setState({ icon })
  }

  onClear = () => {
    this.inputRef.current.value = ''
    this.props.onChange('')
    this.setState({ icon: 'search' })
  }

  onChange = e => {
    this.props.onChange(e.target.value)
  }

  render() {
    let { style, className, ...otherProps } = this.props
    let { icon } = this.state
    let isClearable = icon === 'times'

    return (
      <>
        <style jsx="true">
          {`
            .search-icon {
              right: 0.5rem;
              top: 0.75rem;
              color: var(--bs-gray-600);
            }
            .search-icon.clearable {
              cursor: pointer;
            }
          `}
        </style>
        <div className={`button-container ${className} position-relative`} style={{ ...style }}>
          <i
            className={`fa fa-${this.state.icon} position-absolute search-icon ${isClearable ? 'clearable' : ''}`}
            {...(isClearable ? { onClick: this.onClear } : {})}
          />
          <DebounceInput
            debounceTimeout={300}
            className="form-control pe-5"
            {...otherProps}
            onChange={this.onChange}
            onInput={this.onInput}
            inputRef={this.inputRef}
            placeholder={t('common.search', 'Cerca')}
          />
        </div>
      </>
    )
  }
}

export default SearchButton
