import i18next from 'i18next'

const { t } = i18next

const validateFields = data => {
  let requiredFields = ['name', 'training_for_week', 'duration', 'start_at', 'end_at', 'recoveries']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }

  return errors
}

export default validateFields
