import React from 'react'
import { Dropdown } from 'react-bootstrap'
// import withCapabilities from '/enhancers/with_capabilities'
import styles from 'components/system_wide/actions_dropdown.module.css'

class ActionsDropdown extends React.PureComponent {
  render() {
    let { rowData, actions } = this.props
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${styles.dropdown_toggle}`} variant="secondary">
          <i className={`fas fa-cog`} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {actions.map((action, i) => {
            if (action.text === '---') {
              return <Dropdown.Divider />
            }
            let { disabled = false } = action
            if (typeof disabled === 'function') {
              disabled = disabled(rowData)
            }
            let { visible = true } = action
            if (typeof visible === 'function') {
              visible = visible(rowData)
            }

            return visible === true ? (
              <ActionItem key={i} disabled={disabled} action={action} rowData={rowData} caps={action.caps} />
            ) : null
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

// const ActionItem = withCapabilities({})(props => {
const ActionItem = props => {
  let { disabled, action, rowData } = props
  return (
    <Dropdown.Item disabled={disabled} onClick={() => action.onClick(rowData)}>
      <i className={`fas ${action.icon} pe-1`} />
      {action.text}
    </Dropdown.Item>
  )
}
export default ActionsDropdown
