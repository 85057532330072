import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: '',
  message: '',
}

export const userinfoSlice = createSlice({
  name: 'notifications',
  initialState,

  reducers: {
    addSuccessNotification: (state, action) => {
      state.type = 'SUCCESS'
      state.message = action.payload
    },
    addErrorNotification: (state, action) => {
      state.type = 'ERROR'
      state.message = action.payload
    },
    resetNotification: state => {
      state.type = ''
      state.message = ''
    },
  },
})

export const { addErrorNotification, addSuccessNotification, resetNotification } = userinfoSlice.actions
export default userinfoSlice.reducer
