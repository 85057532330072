import React from 'react'
import { connect } from 'react-redux'
import cookies from 'browser-cookies'
import axios from '../../axios'
import config from '../../config'
import { logout } from 'store/userinfo'

class Logout extends React.Component {
  async componentDidMount() {
    await axios({ url: `${config.SERVER_API_URL}/users`, method: 'GET' })
    cookies.erase('admin-cookie')
    this.props.dispatch(logout())
    window.location = '/login'
  }
  render() {
    return null
  }
}

export default connect()(Logout)
