import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import hash from 'object-hash'

import validateFields from 'lib/validators/payments'
import { updateErrors } from 'store/payments'
import { getMySubscriptions } from 'store/subscriptions'

const initialState = {
  id: null,
  kind: 'Incasso',
  amount: 1,
  cause_id: 1,
  user_id: null,
  subscription_id: null,
  training_id: null,
  comment: '',
}

class ElementForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    createCause: () => {},
    onSave: () => {},
    element: initialState,
    users: [],
    causes: [],
  }
  state = {
    element: initialState,
    element_hash: '',
    errors: {},
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  componentDidMount() {
    this.setElement()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleChange = ({ target: { name, value } }) => {
    let element = { ...this.state.element }
    let errors = { ...this.state.errors }
    element[name] = value
    delete errors[name]
    this.setState({ element, errors }, () => this.updateHash())
  }

  handleConfirm = () => {
    let { element } = this.state
    let errors = { ...this.state.errors }
    if (element.amount > 0) {
      this.props.onConfirm(this.state.element)
    } else {
      errors['amount'] = 'Il valore deve essere superiore a 0'
      this.setState({ errors })
    }
  }

  render() {
    let { element, errors } = this.state
    let { id, kind, amount, cause_id, user_id, subscription_id } = element
    let { causes, users, my_subscriptions } = this.props

    return (
      <Modal backdrop="static" centered show={true} onHide={this.closeNewCause}>
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Tipologia:</Form.Label>
                <span> *</span>
                <Form.Select value={kind} disabled={true}>
                  <option key={`st-opt-incasso`} value="Incasso">
                    Incasso
                  </option>
                  <option key={`st-opt-spesa`} value="Spesa">
                    Spesa
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Euro</Form.Label>
                <span> *</span>
                <Form.Control
                  name="amount"
                  value={amount}
                  type="number"
                  onChange={this.handleChange}
                  isInvalid={'amount' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Causale:</Form.Label>
                <span> *</span>
                <div className="d-flex">
                  <Form.Select value={cause_id} disabled={true}>
                    {causes.map(c => (
                      <option key={`c-opt-${c.id}`} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row border mt-5">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Utente:</Form.Label>
                <div className="d-flex">
                  <Form.Select value={user_id} disabled={true}>
                    <option key={`c-opt-null`} value={null}>
                      Nessun utente
                    </option>
                    {users.map(c => (
                      <option key={`c-opt-${c.id}`} value={c.id}>
                        {c.first_name} {c.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Abbonamento:</Form.Label>
                <div className="d-flex">
                  <Form.Select value={subscription_id} disabled={true}>
                    <option key={`c-opt-null`} value={null}>
                      Nessun abbonamento
                    </option>
                    {my_subscriptions.map(c => (
                      <option key={`c-opt-${c.id}`} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose} variant="secondary">
            Annulla
          </Button>
          <Button onClick={this.handleConfirm} variant="primary">
            Aggiungi
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ElementForm
