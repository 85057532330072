import * as React from 'react'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import { resetNotification } from 'store/notifications'

const mapStateToProps = state => {
  return {
    notificationType: state.notifications.type,
    notificationMessage: state.notifications.message,
  }
}

class Notification extends React.Component {
  static defaultProps = {}

  componentDidUpdate() {
    if (!this.notificationSystem) return
    switch (this.props.notificationType) {
      case 'ERROR':
        this.notificationSystem.addNotification({
          position: 'tr',
          title: 'Errore',
          message: this.props.notificationMessage,
          autoDismiss: 3,
          level: 'error',
        })
        this.props.dispatch(resetNotification())
        break
      case 'SUCCESS':
        this.notificationSystem.addNotification({
          position: 'tr',
          title: 'Successo',
          message: this.props.notificationMessage,
          autoDismiss: 3,
          level: 'success',
        })
        this.props.dispatch(resetNotification())
        break
      case 'MSG':
        this.notificationSystem.addNotification({
          position: 'tr',
          title: 'Messaggio',
          message: this.props.notificationMessage,
          autoDismiss: 3,
          level: 'info',
        })
        this.props.dispatch(resetNotification())
        break
      default:
        return
    }
  }

  render() {
    var style = {
      Containers: {
        DefaultStyle: {
          marginTop: '3.2rem',
          // marginRight: '-0.4rem',
        },
      },
    }
    return (
      <NotificationSystem
        ref={ns => {
          this.notificationSystem = ns
        }}
        style={style}
      />
    )
  }
}

export default connect(mapStateToProps)(Notification)
