module.exports = {
  name: 'italian_it',
  months: [
    ['Gennaio', 'Gen'],
    ['Febbraio', 'Feb'],
    ['Marzo', 'Mar'],
    ['Aprile', 'Apr'],
    ['Maggio', 'Mag'],
    ['Giugno', 'Giu'],
    ['Luglio', 'Lug'],
    ['Agosto', 'Ago'],
    ['Settembre', 'Set'],
    ['Ottobre', 'Ott'],
    ['Novembre', 'Nov'],
    ['Dicembre', 'Dic'],
  ],
  weekDays: [
    ['Domenica', 'Dom'],
    ['Lunedì', 'Lun'],
    ['Martedì', 'Mar'],
    ['Mercoledì', 'Mer'],
    ['Giovedì', 'Gio'],
    ['Venerdì', 'Ven'],
    ['Sabato', 'Sab'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
}
