import React from 'react'
import withRouter from 'utils/withRouter.js'
import NavBar from 'components/common/navbar'
import ListaLevels from './list'

class Levels extends React.Component {
  render() {
    let { pathname } = this.props.router.location
    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Lista livelli" />
        {pathname === '/levels/list' && <ListaLevels />}
      </div>
    )
  }
}

export default withRouter(Levels)
