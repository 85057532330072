import React from 'react'
import withRouter from 'utils/withRouter.js'
import NavBar from 'components/common/navbar'
import ListaRecovery from './list'

class RecoveryRequests extends React.Component {
  render() {
    let { pathname } = this.props.router.location
    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Richieste di recupero" />
        {pathname === '/recovery_requests/list' && <ListaRecovery />}
      </div>
    )
  }
}

export default withRouter(RecoveryRequests)
