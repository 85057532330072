import React from 'react'
import NavBar from 'components/common/navbar'
import { Container, Row, Col } from 'react-bootstrap'
import Cards from './cards'
import Tasks from './tasks'

class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <NavBar title="Dashboard" />
        <Container className="container-fluid" style={{ backgroundColor: '#f8f9fa', marginTop: 40 }}>
          <Cards />
          <Row className="mt-5">
            <Col className="col-12">
              <Tasks />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Dashboard
