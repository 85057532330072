import React from 'react'
import { Button, Form } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'

import validateFields from 'lib/validators/causes'
import { updateErrors } from 'store/causes'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  kind: '',
}

class ElementForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    element: initialState,
  }
  state = {
    element: initialState,
    element_hash: '',
    validated: false,
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  componentDidMount() {
    this.setElement()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleChange = ({ target: { name, value } }) => {
    let element = { ...this.state.element }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    element[name] = value
    this.setState({ element }, () => this.updateHash())
  }
  handleSelectChange = name => e => {
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ element: { ...this.state.element, [name]: e.target.value } }, () => this.updateHash())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.element })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.element)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, element } = this.state
    let { id, name, kind } = element
    let { errors } = this.props
    let title = id
      ? t('elements.element_form.edit_title', 'Modifica')
      : t('elements.element_form.create_title', 'Crea nuovo')

    return (
      <>
        <div className="flex-fill overflow-auto container">
          <h4 className="border-bottom text-primary p-1">{title}</h4>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Nome</Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Group className="mt-3 mb-3">
                <Form.Label>Tipologia:</Form.Label>
                <Form.Select
                  value={kind}
                  onChange={this.handleSelectChange('kind')}
                  isInvalid={validated && 'kind' in errors}>
                  <option key={`st-opt-incasso`} value="Incasso">
                    Incasso
                  </option>
                  <option key={`st-opt-in-attesa`} value="Spesa">
                    Spesa
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default ElementForm
