import React from 'react'
import withRouter from 'utils/withRouter.js'
import NavBar from 'components/common/navbar'
import ListaTopics from './list'

class Topics extends React.Component {
  render() {
    let { pathname } = this.props.router.location
    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Lista fondamentali" />
        {pathname === '/topics/list' && <ListaTopics />}
      </div>
    )
  }
}

export default withRouter(Topics)
