import * as React from 'react'
import { connect } from 'react-redux'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { LuUserCircle2 } from 'react-icons/lu'
import styles from './navbar.module.css'
import { refreshToken } from 'store/userinfo'

const mstp = state => {
  return { user: state.userinfo.user }
}

class CustomNavbar extends React.Component {
  pingCheck = async () => {
    try {
      await this.props.dispatch(refreshToken())
    } catch (err) {
      clearInterval(this.timer)
    }
  }

  componentDidMount = async () => {
    if (!this.props.user) {
      window.location = '/logout'
    }
    clearInterval(this.timer)
    this.timer = setInterval(() => this.pingCheck(), 30000)
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
    this.timer = null
  }

  render() {
    let { title, user } = this.props
    return (
      <Navbar expand="lg" className={`navbar-light bg-light ${styles.navbar}`}>
        <div className="d-flex flex-row ms-4 me-4 w-100">
          <Navbar.Brand className={styles.navbarBrand}>{title}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/profile" className={`d-flex align-items-center ${styles.profile}`}>
                <LuUserCircle2 size={20} style={{ marginLeft: 10 }} />
                <NavDropdown align="end" title={`${user?.first_name}`} menuVariant="dark">
                  <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    )
  }
}

export default connect(mstp)(CustomNavbar)
