import React from 'react'
import { isValid, parse, format } from 'date-fns'
import { Button, Form, Alert, Tabs, Tab } from 'react-bootstrap'
import i18next from 'i18next'
import hash from 'object-hash'
import { Trans } from 'react-i18next'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

import validateFields from 'lib/validators/user'
import { updateErrors, getMyDocuments, removeDocument } from 'store/users'
import DocumentUploader from 'components/system_wide/document-uploader'

const { t } = i18next

const initialState = {
  id: null,
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  gender: '',
  password: '',
  confirm_password: '',
  show_password: true,
  birthday_date: '',
  birthday_city: '',
  birthday_province: '',
  fiscal_code: '',
  telephone_number: '',
  certificate_file: '',
  certificate_expired_at: '',
  level: '',
  aibvc_registration_number: '',
  note: '',
  registration_date: '',
  training_enabled: false,
  enabled: true,
}

class UserForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    user: initialState,
  }
  state = {
    user: initialState,
    user_hash: '',
  }

  setUser = () => {
    let user = {
      ...this.state.user,
      ...this.props.user,
    }
    let show_password = user.id ? false : true
    let user_hash = hash(user)
    this.setState({ user, user_hash, show_password })
    this.initialHash = hash(user)
  }
  componentDidMount() {
    this.setUser()
    if (this.props.user.id) this.props.dispatch(getMyDocuments(this.props.user.id))
  }

  onAddCertificate = () => {
    if (this.props.user.id) this.props.dispatch(getMyDocuments(this.props.user.id))
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.id !== this.props.user.id) {
      this.setUser()
    }
  }

  updateHash = () => {
    let { user } = this.state
    this.setState({ user_hash: hash(user) })
  }

  handleChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    if (name === 'password') delete errors['confirm_password']
    if (name === 'confirm_password') delete errors['password']
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    if (name === 'first_name' || name === 'last_name') {
      user['username'] = (
        user['first_name'].replace(/[\W_]+/g, '') + user['last_name'].replace(/[\W_]+/g, '')
      ).toLowerCase()
    }
    this.setState({ user }, () => this.updateHash())
  }
  handleSelectChange = e => {
    let errors = { ...this.props.errors }
    delete errors['role']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...this.state.user, role: e.target.value } }, () => this.updateHash())
  }
  handleGenderChange = e => {
    let errors = { ...this.props.errors }
    delete errors['gender']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...this.state.user, gender: e.target.value } }, () => this.updateHash())
  }
  handleLevelChange = e => {
    let errors = { ...this.props.errors }
    delete errors['level']
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...this.state.user, level: e.target.value } }, () => this.updateHash())
  }
  handleDateChange = name => date => {
    let newDate = date !== null && isValid(date) ? format(date, 'yyyy-MM-dd') : ''
    this.setState({ user: { ...this.state.user, [name]: newDate } })
  }
  handleIsEnabled = name => e => {
    let user = { ...this.state.user }
    user[name] = e.target.checked
    this.setState({ user }, () => this.updateHash())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.user })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.user)
      if (saved) this.props.closeForm()
    }
    this.setState({ validated: true })
  }

  removeCertificate = id => async () => {
    await this.props.dispatch(removeDocument(id))
    if (this.props.user.id) this.props.dispatch(getMyDocuments(this.props.user.id))
  }

  render() {
    let { validated, user, show_password } = this.state
    let {
      id,
      username,
      first_name,
      last_name,
      email,
      role,
      enabled,
      password,
      birthday_date,
      confirm_password,
      gender,
      birthday_city,
      birthday_province,
      fiscal_code,
      telephone_number,
      certificate_expired_at,
      level,
      aibvc_registration_number,
      note,
      training_enabled,
      registration_date,
    } = user
    let { roles, errors, my_documents, levels } = this.props
    let title = id
      ? t('users.user_form.edit_title', 'Modifica utente')
      : t('users.user_form.create_title', 'Crea utente')

    let today = new Date()
    let parsedBirthdayDate = parse(birthday_date ?? '', 'yyyy-MM-dd', new Date())
    parsedBirthdayDate = isValid(parsedBirthdayDate) ? parsedBirthdayDate : null
    let parsedCertificateEndDate = parse(certificate_expired_at ?? '', 'yyyy-MM-dd', new Date())
    parsedCertificateEndDate = isValid(parsedCertificateEndDate) ? parsedCertificateEndDate : null
    let parsedRegistrationDate = parse(registration_date ?? '', 'yyyy-MM-dd', new Date())
    parsedRegistrationDate = isValid(parsedRegistrationDate) ? parsedRegistrationDate : null

    return (
      <>
        <div className="flex-fill overflow-auto container">
          <h4 className="border-bottom text-primary p-1">{title}</h4>
          <Tabs defaultActiveKey="login" id="uncontrolled-tabs" className="mb-2">
            <Tab eventKey="login" title="Login">
              {errors?.from_server?.length > 0 && (
                <Alert variant="danger">
                  <Alert.Heading>Error:</Alert.Heading>
                  <ul>
                    {errors.from_server.map((err, i) => (
                      <li key={`err-${i}`}>{err.detail}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.name">Nome</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="first_name"
                      value={first_name}
                      onChange={this.handleChange}
                      isInvalid={validated && 'first_name' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="last_name"
                      value={last_name}
                      onChange={this.handleChange}
                      isInvalid={validated && 'last_name' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.username">Username</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      disabled={true}
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                      isInvalid={validated && 'username' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.email">Email</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      isInvalid={validated && 'email' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.role">Ruolo</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Select
                      value={role}
                      onChange={this.handleSelectChange}
                      isInvalid={validated && 'role' in errors}>
                      <option value="">{t('user.user_form.not_role', 'Nessuna selezione')}</option>
                      {roles &&
                        Object.entries(roles).map(role => (
                          <option key={`rol-opt-${role[0]}`} value={role[1]}>
                            {role[1]}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Check
                    className="mt-5"
                    type="switch"
                    id="enabled"
                    name="enabled"
                    label="Abilitato"
                    checked={enabled}
                    onChange={this.handleIsEnabled('enabled')}
                  />
                </div>
                {!show_password && (
                  <div className="row pt-4">
                    <div className="col">
                      <Button
                        variant="primary"
                        onClick={() => this.setState({ show_password: !this.state.show_password })}>
                        <Trans i18nKey="common.button_change_password">Modifica password</Trans>
                      </Button>
                    </div>
                  </div>
                )}
                {show_password && (
                  <div className="row">
                    <div className="col">
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label>
                          <Trans i18nKey="users.user_form.password">Password</Trans>
                        </Form.Label>
                        <span> *</span>
                        <Form.Control
                          name="password"
                          value={password}
                          onChange={this.handleChange}
                          isInvalid={validated && 'password' in errors}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col">
                      <Form.Group className="mt-3 mb-3">
                        <Form.Label>
                          <Trans i18nKey="users.user_form.confirm_password">Conferma password</Trans>
                        </Form.Label>
                        <span> *</span>
                        <Form.Control
                          name="confirm_password"
                          value={confirm_password}
                          onChange={this.handleChange}
                          isInvalid={validated && 'confirm_password' in errors}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
              <div className="row border border-primary mt-5 p-3">
                <div className="col">
                  <Form.Group>
                    <div>
                      <Form.Label>Data iscrizione:</Form.Label>
                      <DatePicker
                        style={{ lineHeight: 1.7 }}
                        className={`form-control ms-3 ${!parsedRegistrationDate ? 'border border-danger' : ''}`}
                        selected={parsedRegistrationDate}
                        dateFormat="yyyy-MM-dd"
                        locale={localeIt}
                        onChange={this.handleDateChange('registration_date')}
                        isClearable
                        showYearDropdown
                        yearDropdownItemNumber={20}
                        scrollableYearDropdown
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="personal_data"
              title={
                <div>
                  Dati anagrafici{' '}
                  {parsedCertificateEndDate <= today && <i className={`fas fa-exclamation-circle ps-1 text-danger`} />}
                </div>
              }>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.role">Genere</Trans>
                    </Form.Label>
                    <Form.Select
                      value={gender}
                      onChange={this.handleGenderChange}
                      isInvalid={validated && 'gender' in errors}>
                      <option value="">{t('user.user_form.not_role', 'Nessuna selezione')}</option>
                      <option key={`rol-opt-maschile`} value={'Maschile'}>
                        Maschile
                      </option>
                      <option key={`rol-opt-femminile`} value={'Femminile'}>
                        Femminile
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.gender}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <div>
                      <Form.Label>Data di nascita</Form.Label>
                      <DatePicker
                        style={{ lineHeight: 1.7 }}
                        className="form-control"
                        selected={parsedBirthdayDate}
                        dateFormat="yyyy-MM-dd"
                        locale={localeIt}
                        onChange={this.handleDateChange('birthday_date')}
                        isClearable
                        showYearDropdown
                        yearDropdownItemNumber={20}
                        scrollableYearDropdown
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-3 mb-3">
                      <Form.Label>Luogo di nascita</Form.Label>
                      <Form.Control
                        name="birthday_city"
                        value={birthday_city}
                        onChange={this.handleChange}
                        isInvalid={validated && 'birthday_city' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.birthday_city}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-3 mb-3">
                      <Form.Label>Provincia di nascita</Form.Label>
                      <Form.Control
                        name="birthday_province"
                        value={birthday_province}
                        onChange={this.handleChange}
                        isInvalid={validated && 'birthday_province' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.birthday_province}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-3 mb-3">
                      <Form.Label>Codice fiscale</Form.Label>
                      <Form.Control
                        name="fiscal_code"
                        value={fiscal_code}
                        onChange={this.handleChange}
                        isInvalid={validated && 'fiscal_code' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.fiscal_code}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mt-3 mb-3">
                      <Form.Label>Numero di telefono</Form.Label>
                      <Form.Control
                        name="telephone_number"
                        value={telephone_number}
                        onChange={this.handleChange}
                        isInvalid={validated && 'telephone_number' in errors}
                      />
                      <Form.Control.Feedback type="invalid">{errors.telephone_number}</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mt-3 mb-3">
                      <Form.Label>Scadenza certificato</Form.Label>
                      <div>
                        <DatePicker
                          style={{ lineHeight: 1.7 }}
                          className={`form-control ${parsedCertificateEndDate <= today ? 'border border-danger' : ''}`}
                          selected={parsedCertificateEndDate}
                          dateFormat="yyyy-MM-dd"
                          locale={localeIt}
                          onChange={this.handleDateChange('certificate_expired_at')}
                          isClearable
                          showYearDropdown
                          yearDropdownItemNumber={20}
                          scrollableYearDropdown
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col">
                    {my_documents.length === 0 && (
                      <DocumentUploader user_id={id} onConfirmChange={this.onAddCertificate} />
                    )}
                    {my_documents.map(d => (
                      <div className="mt-5">
                        <a className="btn btn-primary me-2" href={d.path} target="_blank">
                          <i className="fa fa-download me-2" />
                          Scarica certificato
                        </a>
                        <Button variant="secondary" onClick={this.removeCertificate(d.id)}>
                          <i className="fa fa-close" />
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="profile"
              title={
                <div>
                  Profilo
                  {(!aibvc_registration_number || !training_enabled) && (
                    <i className={`fas fa-exclamation-circle ps-1 text-danger`} />
                  )}
                </div>
              }>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.role">Livello</Trans>
                    </Form.Label>
                    <Form.Select
                      value={level}
                      onChange={this.handleLevelChange}
                      isInvalid={validated && 'level' in errors}>
                      <option value="">{t('user.user_form.not_role', 'Nessuna selezione')}</option>
                      {levels.map(el => (
                        <option key={`rol-opt-${el.name}`} value={el.name}>
                          {el.name}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.level}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Check
                    className="mt-5"
                    type="switch"
                    id="training_enabled"
                    name="training_enabled"
                    label="Abilitato agli allenamenti"
                    checked={training_enabled}
                    onChange={this.handleIsEnabled('training_enabled')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Matricola atleta AIBVC</Form.Label>
                    <Form.Control
                      name="aibvc_registration_number"
                      value={aibvc_registration_number}
                      onChange={this.handleChange}
                      isInvalid={(validated && 'aibvc_registration_number' in errors) || !aibvc_registration_number}
                    />
                    <Form.Control.Feedback type="invalid">{errors.aibvc_registration_number}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Note</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="note"
                      value={note}
                      onChange={this.handleChange}
                      isInvalid={validated && 'note' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="border-top pt-2">
          <Button className="float-end" variant="primary" onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="float-end me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default UserForm
