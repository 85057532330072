import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form, Table } from 'react-bootstrap'

import {
  toggleShowForm,
  toggleDisabledFilter,
  resetCurrentElement,
  setCurrentElement,
  getElements,
  updateElement,
  createElement,
  deleteElement,
  toggleElement,
  filterCicles,
  filterDates,
} from '../../store/subscriptions'
import { getElements as getCicles } from '../../store/cicles'
import { getAthletes } from '../../store/users'
import { getMyPayments, createElement as createPayment } from '../../store/payments'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import ElementForm from './form'
import NewPaymentForm from './new_payment'
import CheckboxFilter from '../system_wide/checkbox_filter'
import DatesFilter from '../system_wide/dates_filter'
import config from 'config'

const mstp = state => {
  let { elements, total, show_disabled, show_form, current_element, errors, filtered_cicles, filtered_dates } =
    state.subscriptions
  let { athletes } = state.users
  let { elements: cicles } = state.cicles
  let { my_payments } = state.payments
  return {
    elements,
    total,
    show_disabled,
    show_form,
    current_element,
    errors,
    athletes,
    cicles,
    my_payments,
    filtered_cicles,
    filtered_dates,
  }
}

class SubscriptionsList extends React.Component {
  static defaultProps = {
    elements: [],
    cicles: [],
    athletes: [],
    my_payments: [],
    getData: () => {},
    columns: [],
    filter: null,
    show_disabled: false,
    filtered_cicles: [],
    filtered_dates: [],
  }

  state = {
    show_modal_toggle: false,
    show_modal_delete: false,
    show_payments: false,
    show_new_payment: false,
    element_to_delete: { id: '', name: '' },
    element_to_toggle: { id: '', name: '', enabled: null },
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-edit',
      text: 'Modifica',
      onClick: rowData => {
        this.props.dispatch(setCurrentElement(rowData))
        this.props.dispatch(toggleShowForm())
      },
    },
    {
      icon: 'fa-dollar',
      text: 'Pagamenti',
      onClick: rowData => {
        this.props.dispatch(setCurrentElement(rowData))
        this.props.dispatch(getMyPayments(rowData.id))
        this.setState({ show_payments: true })
      },
    },
    {
      icon: 'fa-ban',
      text: 'Disattiva',
      visible: rowData => rowData.enabled === 1,
      onClick: ({ id, first_name, last_name, enabled }) => {
        this.setState({
          show_modal_toggle: true,
          element_to_toggle: { id, name: `${last_name} ${first_name}`, enabled },
        })
      },
    },
    {
      icon: 'fa-check',
      text: 'Riattiva',
      visible: rowData => rowData.enabled === 0,
      onClick: ({ id, first_name, last_name, enabled }) => {
        this.setState({
          show_modal_toggle: true,
          element_to_toggle: { id, name: `${first_name} ${last_name}`, enabled },
        })
      },
    },
    {
      icon: 'fa-trash',
      text: 'Elimina',
      onClick: ({ id, name }) => {
        this.setState({
          show_modal_delete: true,
          element_to_delete: { id, name },
        })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  renderEnabled = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-center">
        {rowData.enabled ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
      </div>
    )
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome atleta',
      className: 'd-lg-block flex-fill',
      style: { width: '15%', minWidth: '8rem' },
      sortable: true,
      sortingKey: 'last_name',
      data: ({ rowData }) => `${rowData.first_name} ${rowData.last_name}`,
    },
    {
      title: 'Tipo abbonamento',
      className: 'd-lg-block flex-fill',
      style: { width: '21%', minWidth: '8rem' },
      sortable: true,
      data: 'cicle_name',
    },
    {
      title: 'Costo',
      className: 'd-lg-block flex-fill',
      style: { width: '6%', minWidth: '3rem' },
      sortable: true,
      data: ({ rowData }) => `${rowData.price} €`,
    },
    {
      title: 'Pagamenti',
      className: 'd-lg-block flex-fill',
      style: { width: '8%', minWidth: '3rem' },
      sortable: true,
      data: ({ rowData: { payments, price } }) => (
        <div
          className={`p-1 text-end ${payments < price ? 'bg-warning' : 'bg-success text-white'} ${!payments ? 'bg-danger text-white' : ''}`}>
          {payments ?? 0} €
        </div>
      ),
    },
    {
      title: 'Tipologia pagamento',
      className: 'd-lg-block flex-fill',
      style: { width: '15%', minWidth: '8rem' },
      sortable: true,
      data: 'kind',
    },
    {
      title: 'Data',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '12%', minWidth: '10rem' },
      sortable: true,
      data: 'start_at',
    },
    {
      title: 'Attivo',
      className: 'd-none d-lg-block flex-fill text-center',
      style: { width: '6%', minWidth: '4rem' },
      sortable: true,
      data: this.renderEnabled,
    },
    {
      title: 'Recuperi',
      className: 'd-lg-block flex-fill text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: 'recoveries',
    },
  ]

  async componentDidMount() {
    this.tableRef.current.refreshData()
    await this.props.dispatch(getCicles({ offset: 0, limit: 0, sortData: {}, filter: '' }))
  }
  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getElements({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }
  componentWillUnmount() {
    this.props.dispatch(filterDates([]))
  }

  onToggleForm = async () => {
    await this.props.dispatch(getAthletes())
    await this.props.dispatch(getCicles({ offset: 0, limit: 1000, sortData: {}, filter: '' }))
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentElement())
    }
    this.props.dispatch(toggleShowForm())
  }

  onConfirmToggle = async () => {
    let el = this.state.element_to_toggle
    await this.props.dispatch(toggleElement(el.id))
    this.setState({ show_modal_toggle: false, element_to_toggle: { id: '', name: '', enabled: null } })
    this.tableRef.current.refreshData()
  }

  onCancelDelete = () => {
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
  }
  onCancelToggle = () => {
    this.setState({ show_modal_toggle: false, element_to_toggle: { id: '', name: '', enabled: null } })
  }
  onCancelPayments = () => {
    this.setState({ show_payments: false })
    this.props.dispatch(resetCurrentElement())
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => this.setState({ filter })
  onChangeFilterDisabled = () => {
    this.props.dispatch(toggleDisabledFilter())
    this.tableRef.current.refreshData()
  }

  saveElement = async element => {
    try {
      if (element.id !== null) {
        await this.props.dispatch(updateElement(element))
        this.tableRef.current.refreshData()
      } else {
        await this.props.dispatch(createElement(element))
        this.tableRef.current.refreshData()
      }
      return true
    } catch (err) {
      return false
    }
  }
  onConfirmDelete = async () => {
    let element = this.state.element_to_delete
    await this.props.dispatch(deleteElement(element.id))
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }

  onAddPayment = () => this.setState({ show_new_payment: true })
  onCloseAddPayment = () => this.setState({ show_new_payment: false })
  onConfirmPayment = async element => {
    await this.props.dispatch(createPayment(element))
    await this.props.dispatch(getMyPayments(element.subscription_id))
    this.tableRef.current.refreshData()
    this.setState({ show_new_payment: false })
  }

  rowClasses = ({ enabled }) => (enabled === 0 ? 'bg-warning bg-gradient bg-opacity-50' : '')

  onFilterCicles = cicles => {
    this.props.dispatch(filterCicles(cicles))
    this.tableRef.current.refreshData()
  }
  onChangeDatesFilter = dates => {
    this.props.dispatch(filterDates(dates))
    this.tableRef.current.refreshData()
  }
  handleExport = () => {
    let { filtered_cicles, filtered_dates } = this.props
    let url = `${config.SERVER_API_URL}/subscriptions/export?`
    if (filtered_cicles.length > 0) url = url + `&_cicles=${encodeURIComponent(filtered_cicles.join(','))}`
    if (filtered_dates.length > 0) url = url + `&_dates=${filtered_dates.join(',')}`
    window.open(url, '_blank')
  }

  render() {
    let { elements, total, current_element, show_form, errors, athletes, cicles, my_payments, filtered_cicles } =
      this.props
    if (!elements) return null
    let payments_total = 0
    let ciclesOptions = cicles.map(v => ({ id: v.id, name: v.name }))

    return (
      <div className="container-fluid flex-fill d-flex flex-column pt-3 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <Button onClick={this.onToggleForm} disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi Sottoscrizione
                </Button>
              </div>
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
                <CheckboxFilter
                  className="float-md-start ms-5 me-2"
                  label={'Tipo abbonamento'}
                  items={ciclesOptions}
                  filteredItems={filtered_cicles}
                  onChangeFilter={this.onFilterCicles}
                />
                <DatesFilter onSubmit={this.onChangeDatesFilter} />
              </div>
              <Button onClick={this.handleExport} disabled={this.props.show_form} className="ms-2 mt-0">
                <i className="fas fa-download fa-fw" />
              </Button>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={elements}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={ElementForm}
            menuContentProps={{
              element: current_element,
              athletes: athletes,
              cicles: cicles,
              closeForm: this.onToggleForm,
              onSave: this.saveElement,
              errors,
              dispatch: this.props.dispatch,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina elemento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad eliminiare <b>{this.state.element_to_delete.name}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDelete} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_modal_toggle} onHide={this.onCancelToggle}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.element_to_toggle.enabled === 1 ? 'Disattiva' : 'Attiva'} utente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere sul <b>{this.state.element_to_toggle.name}</b> click su Prosegui
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelToggle} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmToggle} variant="warning">
              Prosegui
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_payments} onHide={this.onCancelPayments}>
          <Modal.Header closeButton>
            <Modal.Title>Lista pagamenti</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-muted d-flex flex-column border p-3">
              <span>
                Utente:{' '}
                <b>
                  {current_element.first_name} {current_element.last_name}
                </b>
              </span>
              <span>
                Tipo abbonamento: <b>{current_element.cicle_name}</b>
              </span>
              <span>
                Tipologia pagamento: <b>{current_element.kind}</b>
              </span>
              <span className="mt-2">
                Costo: <b className="text-success fw-bold">{current_element.price} €</b>
              </span>
            </div>
            {my_payments.length > 0 && (
              <Table className="mt-4" striped bordered hover>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th className="text-end">Importo</th>
                  </tr>
                </thead>
                <tbody>
                  {my_payments.map(p => {
                    payments_total = payments_total + p.amount
                    return (
                      <tr>
                        <td>{p.created_at.split('T')[0]}</td>
                        <td className="text-end">{p.amount} €</td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Totale</th>
                    <th
                      className={`text-end ${payments_total === current_element.price ? 'bg-success text-white' : 'bg-warning'}`}>
                      {payments_total} €
                    </th>
                  </tr>
                </tfoot>
              </Table>
            )}
            {payments_total < current_element.price && (
              <div className="text-danger text-end">
                <b>NB</b>: Abbonamento non saldato completamente!
              </div>
            )}
            {my_payments.length === 0 && <div className="border p-3">Nessun pagamento ricevuto</div>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onAddPayment} variant="primary">
              Aggiungi Pagamento
            </Button>
            <Button onClick={this.onCancelPayments} variant="secondary">
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.show_new_payment && (
          <NewPaymentForm
            onClose={this.onCloseAddPayment}
            onConfirm={this.onConfirmPayment}
            users={[
              {
                id: current_element.user_id,
                first_name: current_element.first_name,
                last_name: current_element.last_name,
              },
            ]}
            causes={[{ id: 1, name: 'Abbonamento' }]}
            my_subscriptions={[{ id: current_element.id, name: current_element.cicle_name }]}
            element={{ user_id: current_element.user_id, subscription_id: current_element.id }}
          />
        )}
      </div>
    )
  }
}

export default connect(mstp)(SubscriptionsList)
