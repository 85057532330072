import React from 'react'
import withRouter from 'utils/withRouter.js'
import NavBar from 'components/common/navbar'
import ListaPagamenti from './list'

class Payments extends React.Component {
  render() {
    let { pathname } = this.props.router.location
    return (
      <div className="d-flex flex-column h-100">
        <NavBar title="Lista incassi/spese" />
        {pathname === '/payments/list' && <ListaPagamenti />}
      </div>
    )
  }
}

export default withRouter(Payments)
