import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'

import {
  toggleShowForm,
  toggleDisabledFilter,
  resetCurrentElement,
  setCurrentElement,
  getElements,
  updateElement,
  createElement,
  deleteElement,
  approveElement,
} from '../../store/recovery_requests'
import ResponsiveTable from '../system_wide/responsive_table'
import ActionsDropdown from '../system_wide/actions_dropdown'
import SearchBox from '../system_wide/search_box'
import ElementForm from '../recovery_requests/form'

const mstp = state => {
  let { elements, total, show_form, current_element, errors } = state.recovery_requests
  return {
    elements,
    total,
    show_form,
    current_element,
    errors,
  }
}

class RecoveryRequestsList extends React.Component {
  static defaultProps = {
    elements: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    show_modal_delete: false,
    show_modal_approve: false,
    element: { id: '', name: '' },
    filter: '',
  }

  tableRef = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  actions = [
    {
      icon: 'fa-check',
      text: 'Approva',
      onClick: ({ id, first_name, last_name }) => {
        this.setState({
          show_modal_approve: true,
          element: { id, name: `${first_name} ${last_name}` },
        })
      },
    },
    {
      icon: 'fa-trash',
      text: 'Elimina',
      onClick: ({ id, first_name, last_name }) => {
        this.setState({
          show_modal_delete: true,
          element: { id, name: `${first_name} ${last_name}` },
        })
      },
    },
  ]

  actionsRenderer = ({ rowData }) => {
    return <ActionsDropdown rowData={rowData} actions={this.actions} />
  }

  renderUser = ({ rowData }) => {
    return (
      <div>
        {rowData.first_name} {rowData.last_name}
      </div>
    )
  }
  renderApproved = ({ rowData }) => {
    if (!rowData.approved) {
      return (
        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              this.setState({
                show_modal_approve: true,
                element: { id: rowData.id, name: `${rowData.first_name} ${rowData.last_name}` },
              })
            }}>
            Approva
          </Button>
        </div>
      )
    } else {
      return (
        <div className="text-success fw-bold">
          Approvato
          <i className="fa fa-check ms-2" />
        </div>
      )
    }
  }

  renderRecoveries = ({ rowData }) => {
    let { used_recoveries, recoveries, approved } = rowData
    let color = recoveries > used_recoveries ? 'bg-success' : 'bg-warning'
    if (used_recoveries === recoveries) color = 'bg-danger'
    if (used_recoveries + 1 === recoveries) color = 'bg-warning'
    if (approved) {
      return '--'
    } else {
      return (
        <div className={`${color} text-white text-center fw-bold p-1`}>
          {used_recoveries} / {recoveries}
        </div>
      )
    }
  }

  columns = [
    {
      title: '',
      className: 'd-lg-block text-center',
      style: { width: '6%', minWidth: '4rem' },
      data: this.actionsRenderer,
    },
    {
      title: 'Nome',
      className: 'd-lg-block flex-fill',
      style: { width: '20%', minWidth: '8rem' },
      sortable: true,
      sortingKey: 'user',
      data: this.renderUser,
    },
    {
      title: 'Data richiesta',
      className: 'd-lg-block flex-fill',
      style: { width: '20%', minWidth: '8rem' },
      sortable: true,
      data: 'created_at',
    },
    {
      title: 'Recuperi disponibili',
      className: 'd-lg-block flex-fill',
      style: { width: '15%', minWidth: '8rem' },
      data: this.renderRecoveries,
    },
    {
      title: 'Approvato',
      className: 'd-none d-lg-block flex-fill',
      style: { width: '20%', minWidth: '10rem' },
      sortable: true,
      data: this.renderApproved,
    },
  ]

  componentDidMount() {
    this.tableRef.current.refreshData()
  }
  getData = async (index = 0, chunkSize, sortData, filter) => {
    await this.props.dispatch(getElements({ offset: index - 1, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentElement())
    }
    this.props.dispatch(toggleShowForm())
  }

  onCloseModal = () => {
    this.setState({ show_modal_delete: false, show_modal_approve: false, element: { id: '', name: '' } })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => this.setState({ filter })
  onChangeFilterDisabled = () => {
    this.props.dispatch(toggleDisabledFilter())
    this.tableRef.current.refreshData()
  }

  saveElement = async element => {
    try {
      if (element.id !== null) {
        await this.props.dispatch(updateElement(element))
        this.tableRef.current.refreshData()
      } else {
        await this.props.dispatch(createElement(element))
        this.tableRef.current.refreshData()
      }
      return true
    } catch (err) {
      return false
    }
  }
  onConfirmDelete = async () => {
    let element = this.state.element
    await this.props.dispatch(deleteElement(element.id))
    this.setState({ show_modal_delete: false, element: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }
  onConfirmApprove = async () => {
    let element = this.state.element
    await this.props.dispatch(approveElement(element.id))
    this.setState({ show_modal_approve: false, element: { id: '', name: '' } })
    this.tableRef.current.refreshData()
  }

  rowClasses = ({ enabled }) => (enabled === 0 ? 'bg-warning bg-gradient bg-opacity-50' : '')

  render() {
    let { elements, total, current_element, show_form, errors } = this.props
    if (!elements) return null

    return (
      <div className="container-fluid flex-fill d-flex flex-column pt-3 zi-1 h-100 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              {/* <div className="flex-fill flex-sm-grow-0 flex-sm-shrink-0"> */}
              {/*   <Button onClick={this.onToggleForm} disabled={this.props.show_form}> */}
              {/*     <i className="fas fa-plus fa-fw text-start" /> */}
              {/*     Aggiungi Recupero */}
              {/*   </Button> */}
              {/* </div> */}
              <div className="flex-fill">
                <SearchBox
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  disabled={this.props.show_form}
                  className="float-md-end ms-3"
                />
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth="45rem"
            data={elements}
            getData={this.getData}
            totalRecords={total}
            columns={this.columns}
            showMenu={show_form}
            menuContent={ElementForm}
            menuContentProps={{
              element: current_element,
              closeForm: this.onToggleForm,
              onSave: this.saveElement,
              errors,
              dispatch: this.props.dispatch,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere all'eliminizione della richiesta di <b>{this.state.element.name}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_modal_approve} onHide={this.onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Approva recupero</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad approvare il recupero di <b>{this.state.element.name}</b> click su Approva
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCloseModal} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmApprove} variant="success">
              Approva
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default connect(mstp)(RecoveryRequestsList)
