import React from 'react'
import { connect } from 'react-redux'
import { isValid, format } from 'date-fns'
import { Button, Form, Modal } from 'react-bootstrap'
import hash from 'object-hash'
import 'react-datepicker/dist/react-datepicker.css'

import validateFields from 'lib/validators/reservations'
import { updateErrors } from 'store/reservations'
import { getAthletes } from 'store/users'
import { levels } from 'utils/constants'

const initialState = {
  id: null,
  training_id: null,
  user_id: null,
  status: 'Prenotata',
}

const mstp = state => {
  let { current_element: element, errors } = state.reservations
  let { athletes } = state.users

  return {
    users: athletes,
    element,
    errors,
  }
}

class ElementFormForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    element: initialState,
    trainings: [],
    users: [],
  }
  state = {
    element: initialState,
    element_hash: '',
    validated: false,
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  async componentDidMount() {
    this.setElement()
    await this.props.dispatch(getAthletes())
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleSelectChange = name => e => {
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ element: { ...this.state.element, [name]: e.target.value } }, () => this.updateHash())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.element })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.element)
      if (saved) this.props.onCancel()
    }
    this.setState({ validated: true })
  }

  render() {
    let { show_form, trainings, users } = this.props
    let { validated, element } = this.state
    let { id, user_id, training_id, status } = element
    let { errors } = this.props

    let training = trainings.find(el => parseInt(el.id) === parseInt(training_id))
    let user = users.find(el => parseInt(el.id) === parseInt(user_id))
    return (
      <Modal backdrop="static" centered size="lg" show={show_form} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? 'Modifica' : 'Aggiungi'} prenotazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-fill overflow-auto container">
            <div className="row">
              <div className="col">
                <Form.Group className="mt-3 mb-3 d-flex flex-row align-items-center">
                  <div style={{ minWidth: 200 }}>Stato prenotazione:</div>
                  <Form.Select
                    value={status}
                    onChange={this.handleSelectChange('status')}
                    isInvalid={validated && 'status' in errors}>
                    <option key={`st-opt-prenotata`} value="Prenotata">
                      Prenotata
                    </option>
                    <option key={`st-opt-in-attesa`} value="In attesa">
                      In attesa
                    </option>
                    <option key={`st-opt-cancellata`} value="Cancellata">
                      Cancellata
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>Allenamento:</Form.Label>
                  <Form.Select
                    value={training_id}
                    onChange={this.handleSelectChange('training_id')}
                    isInvalid={validated && 'training_id' in errors}>
                    <option value="1">Nessuna selezione</option>
                    {trainings.map(el => {
                      return (
                        <option key={`tr-opt-${el.id}`} value={el.id}>
                          {el.level} - {el.gender} - {el.start.split(' ')[1].slice(0, -3)}/
                          {el.end.split(' ')[1].slice(0, -3)}
                        </option>
                      )
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.training_id}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>Atleta:</Form.Label>
                  <Form.Select
                    value={user_id}
                    onChange={this.handleSelectChange('user_id')}
                    isInvalid={validated && 'user_id' in errors}>
                    <option value="1">Nessuna selezione</option>
                    {users.map(el => {
                      return (
                        <option key={`us-opt-${el.id}`} value={el.id}>
                          {el.first_name} - {el.last_name}
                        </option>
                      )
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.user_id}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {training_id && (
                  <div
                    className="d-flex flex-column text-secondary"
                    style={{
                      borderLeft: 5,
                      borderLeftColor: levels[training.level]?.bgColor,
                      borderLeftStyle: 'solid',
                      padding: 10,
                    }}>
                    <div>
                      Fondamentale: <b>{training.title}</b>
                    </div>
                    <div>
                      Livello: <b>{training.level ?? '-'}</b>
                    </div>
                    <div>
                      Genere: <b>{training.gender ?? '-'}</b>
                    </div>
                    <div>
                      Allenatore: <b>{training.trainer ?? '-'}</b>
                    </div>
                  </div>
                )}
              </div>
              <div className="col">
                {user_id && (
                  <div
                    className="d-flex flex-column text-secondary"
                    style={{
                      borderLeft: 5,
                      borderLeftColor: levels[user.level]?.bgColor,
                      borderLeftStyle: 'solid',
                      padding: 10,
                    }}>
                    <div>
                      Nome:{' '}
                      <b>
                        {user.first_name} {user.last_name}
                      </b>
                    </div>
                    <div>
                      Livello: <b>{user.level ?? '-'}</b>
                    </div>
                    <div>
                      Genere: <b>{user.gender ?? '-'}</b>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancel} variant="secondary">
            Annulla
          </Button>
          <Button onClick={this.handleConfirm} variant="primary">
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(ElementFormForm)
