export const levels = {
  Base: 1,
  Intermedio: 2,
  Avanzato: 3,
  Pro: 4,
}

export const statuses = {
  Prenotata: { bgColor: 'rgb(128, 197, 246)' },
  'In attesa': { bgColor: 'orange' },
  Cancellata: { bgColor: 'red' },
  Extra: { bgColor: 'orange' },
  Recupero: { bgColor: 'violet' },
}

export const levelsFn = (level = 1, gender) => {
  let colors = { Femminile: [255, 220, 220], Maschile: [220, 240, 255], Misto: [200, 255, 200] }
  let baseColor = colors?.[gender] ?? null
  if (baseColor !== null) {
    let offset = level * 80
    let r = baseColor[0] === 255 ? 255 : baseColor[0] - offset
    let g = baseColor[1] === 255 ? 255 : baseColor[1] - offset
    let b = baseColor[2] === 255 ? 255 : baseColor[2] - offset

    if (r < 0 && g < 0) {
      b = baseColor[2] + r
      r = 0
      g = 0
    } else if (g < 0 && b < 0) {
      r = baseColor[0] + g
      g = 0
      b = 0
    } else if (r < 0 && b < 0) {
      g = baseColor[1] + r
      r = 0
      b = 0
    }
    return `rgb(${r},${g},${b})`
  } else {
    return ''
  }
}
