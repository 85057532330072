import React from 'react'
import { connect } from 'react-redux'
import { startOfWeek, endOfWeek, addDays } from 'date-fns'
import { Modal, Card, Button, ButtonGroup } from 'react-bootstrap'

import {
  resetCurrentElement,
  setCurrentElement,
  getElements as getReservations,
  updateElement,
  createElement,
  deleteElement,
} from '../../store/reservations'
import { getElements as getTrainings } from '../../store/trainings'
import { levels, statuses } from 'utils/constants'
import WeekPicker from 'components/system_wide/weekpicker'
import DayPicker from 'components/system_wide/daypicker'
import ElementForm from './form'

const mstp = state => {
  let { elements: reservations, total, current_element, errors } = state.reservations
  let { elements: trainings } = state.trainings

  let elements = trainings.map(tr => ({ ...tr, reservations: reservations.filter(re => re.training_id === tr.id) }))

  let sortedElements = [...elements]
  sortedElements.sort((a, b) => (a.start < b.start ? -1 : 1))
  sortedElements = sortedElements.map(el => ({ ...el, bgColor: levels[el.level]?.bgColor ?? '' }))

  return {
    elements: sortedElements,
    reservations,
    trainings,
    total,
    current_element,
    errors,
  }
}

class ReservationsList extends React.Component {
  static defaultProps = {
    elements: [],
    reservations: [],
    getData: () => {},
  }

  constructor(props) {
    super(props)
    let selectedDate = new Date()
    this.state = {
      show_form: false,
      show_modal_delete: false,
      element_to_delete: { id: '', name: '' },
      selectedDate,
      startDate: selectedDate,
      endDate: selectedDate,
      type: 'day',
    }
  }

  getData = async () => {
    let { startDate, endDate } = this.state
    let start = startDate.toLocaleDateString('sv')
    let end = endDate.toLocaleDateString('sv')
    await this.props.dispatch(getTrainings({ offset: 0, limit: '', sortData: {}, start, end }))
    await this.props.dispatch(getReservations({ offset: 0, limit: '', sortData: {}, start, end }))
  }

  async componentDidMount() {
    this.getData()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDate !== this.state.selectedDate) {
      let date = new Date(this.state.selectedDate)
      let startDate = date
      let endDate = date
      if (this.state.type === 'week') {
        startDate = startOfWeek(date, { weekStartsOn: 1 })
        endDate = endOfWeek(date, { weekStartsOn: 1 })
      }
      this.setState({ startDate, endDate }, () => this.getData())
    }
  }

  onCancelDelete = () => {
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
  }

  saveElement = async element => {
    try {
      if (element.id !== null) {
        await this.props.dispatch(updateElement(element))
      } else {
        await this.props.dispatch(createElement(element))
      }
      this.getData()
      return true
    } catch (err) {
      return false
    }
  }

  onConfirmDelete = async () => {
    let element = this.state.element_to_delete
    await this.props.dispatch(deleteElement(element.id))
    this.setState({ show_modal_delete: false, element_to_delete: { id: '', name: '' } })
    this.getData()
  }
  onOpenDelete = reservation => () => {
    this.setState({ show_modal_delete: true, element_to_delete: { id: reservation.id, name: reservation.user } })
  }

  onToggleType = () => {
    let date = new Date(this.state.selectedDate)
    let startDate = date
    let endDate = date
    if (this.state.type === 'day') {
      startDate = startOfWeek(date, { weekStartsOn: 1 })
      endDate = endOfWeek(date, { weekStartsOn: 1 })
    }
    this.setState({ type: this.state.type === 'day' ? 'week' : 'day', startDate, endDate }, () => this.getData())
  }
  onToggleForm = () => this.setState({ show_form: !this.state.show_form })

  renderTraining = el => {
    let { title, level, gender, bgColor, start, end, trainer, reservations, partecipants } = el
    return (
      <>
        <div
          className="p-2 pb-1 fw-bold bg-gradient-primary"
          style={{
            color: 'brown',
            background: 'var(--bs-primary-border-subtle)',
          }}>
          {start.split(' ')[1].slice(0, -3)} - {end.split(' ')[1].slice(0, -3)}
        </div>
        <div
          style={{
            border: 1,
            borderColor: 'lightgrey',
            borderStyle: 'solid',
            padding: 10,
            paddingBottom: 20,
          }}>
          <div
            className="d-flex flex-row"
            style={{
              borderLeft: 5,
              borderLeftColor: bgColor,
              borderLeftStyle: 'solid',
              padding: 10,
            }}>
            <div className="d-flex flex-column text-secondary" style={{ minWidth: '50%' }}>
              <div>
                Fondamentale: <b>{title}</b>
              </div>
              <div>
                Livello: <b>{level ?? '-'}</b>
              </div>
              <div>
                Genere: <b>{gender ?? '-'}</b>
              </div>
              <div>
                Allenatore: <b>{trainer ?? '-'}</b>
              </div>
            </div>
            <div className="d-flex flex-column text-secondary">
              <div className="mb-2">
                Partecipanti{' '}
                <b>
                  ({reservations.length}/{partecipants})
                </b>
              </div>
              {reservations.length === 0 && <div className="text-info">Nessun allievo registrato</div>}
              {reservations.map(re => (
                <div>
                  <span style={{ color: 'darkblue', fontWeight: 'bold' }}>{re.user}</span>:{' '}
                  <span className="fw-bold" style={{ color: statuses?.[re.status]?.bgColor ?? 'grey' }}>
                    {re.status}
                  </span>
                  <span onClick={this.onOpenDelete(re)}>
                    <i className="fa fa-times-circle p-2" />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    let { elements } = this.props
    let { selectedDate, type, startDate, endDate } = this.state
    if (!elements) return null

    let dates = []
    for (let d = startDate; d <= endDate; d = addDays(d, 1)) {
      dates.push(d)
    }

    return (
      <div className="container-fluid flex-fill d-flex flex-column pt-3 zi-1 h-50 w-100">
        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap gap-2">
              <div className="d-flex flex-fill flex-sm-grow-0 flex-sm-shrink-0">
                <Button onClick={this.onToggleForm} disabled={this.state.show_form} className="me-3">
                  <i className="fas fa-plus fa-fw text-start" />
                  Aggiungi prenotazione
                </Button>
              </div>
              <div className="d-flex flex-fill justify-content-end">
                {type === 'week' && (
                  <WeekPicker date={selectedDate} onChange={date => this.setState({ selectedDate: date })} />
                )}
                {type === 'day' && (
                  <DayPicker date={selectedDate} onChange={date => this.setState({ selectedDate: date })} />
                )}
                <ButtonGroup className="border ms-2 me-1">
                  <Button variant={type === 'day' ? 'primary' : 'white'} onClick={this.onToggleType}>
                    Day
                  </Button>
                  <Button variant={type === 'week' ? 'primary' : 'white'} onClick={this.onToggleType}>
                    Week
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="d-flex flex-fill flex-column">
            {dates.map(d => {
              let date = new Date(d).toLocaleDateString('sv')
              let day = date.split('-')[2]
              let month = new Date(d).toLocaleString('default', { month: 'long' })
              let els = elements.filter(el => el.start.includes(date))

              return (
                <Card style={{ marginBottom: 10, marginRight: 3 }} className="shadow-sm">
                  <Card.Header className="text-capitalize fw-bold">
                    {day} {month}
                  </Card.Header>
                  <Card.Body style={{ padding: 0 }}>
                    {els.map(el => this.renderTraining(el))}
                    {els.length === 0 && <div className="p-4">Nessun allenamento programmato</div>}
                  </Card.Body>
                </Card>
              )
            })}
          </div>
        </div>
        {this.state.show_modal_delete && (
          <Modal backdrop="static" centered show={true} onHide={this.onCancelDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Elimina prenotazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Per procedere ad eliminiare <b>{this.state.element_to_delete.name}</b> click su Elimina
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.onCancelDelete} variant="secondary">
                Annulla
              </Button>
              <Button onClick={this.onConfirmDelete} variant="danger">
                Elimina
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.show_form && (
          <ElementForm
            show_form={this.state.show_form}
            onCancel={this.onToggleForm}
            onSave={this.saveElement}
            trainings={elements}
          />
        )}
      </div>
    )
  }
}

export default connect(mstp)(ReservationsList)
