import React from 'react'
import { connect } from 'react-redux'
import { isValid, parse, format } from 'date-fns'
import { Button, Form, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

const initialState = {
  id: null,
  title: '',
  start: '',
  end: '',
  trainer_id: null,
  resourceId: '',
  partecipants: 1,
  gender: '',
  level: '',
}

const mstp = state => {
  return {}
}

class PlannerForm extends React.Component {
  static defaultProps = {
    onCancel: () => {},
    onConfirm: () => {},
  }
  state = {
    errors: {},
    validated: false,
    start: '',
    end: '',
  }

  validateFields = () => {
    let { start, end, errors } = this.state
    if (start === '') errors['start'] = 'Campo richiesto'
    if (end === '') errors['end'] = 'Campo richiesto'
    return errors
  }

  handleDateChange = name => date => {
    let errors = { ...this.state.errors }
    delete errors[name]
    this.setState({ errors })
    let newDate = date !== null && isValid(date) ? format(date, 'yyyy-MM-dd') : ''
    this.setState({ [name]: newDate })
  }

  handleConfirm = async () => {
    let errors = this.validateFields()
    if (Object.keys(errors).length) {
      this.setState({ errors })
    } else {
      let { start, end } = this.state
      let saved = await this.props.onConfirm({ start, end })
      if (saved) this.props.onCancel()
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, errors, start, end } = this.state
    let { show_modal_clone } = this.props

    let parsedStartAt = parse(start ?? '', 'yyyy-MM-dd', new Date())
    parsedStartAt = isValid(parsedStartAt) ? parsedStartAt : null
    let parsedEndAt = parse(end ?? '', 'yyyy-MM-dd', new Date())
    parsedEndAt = isValid(parsedEndAt) ? parsedEndAt : null

    return (
      <Modal backdrop="static" centered size="lg" show={show_modal_clone} onHide={this.props.onCancelClone}>
        <Modal.Header closeButton>
          <Modal.Title>Clona giorno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-fill overflow-auto container">
            <div className="row">
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>Data origine:</Form.Label>
                  <span> *</span>
                  <div>
                    <DatePicker
                      style={{ lineHeight: 1.7 }}
                      className={`form-control ${validated && 'start' in errors ? 'border border-danger' : ''}`}
                      selected={parsedStartAt}
                      dateFormat="yyyy-MM-dd"
                      locale={localeIt}
                      maxDate={parsedEndAt}
                      onChange={this.handleDateChange('start')}
                      isClearable
                      showYearDropdown
                      yearDropdownItemNumber={20}
                      scrollableYearDropdown
                    />
                    {'start' in errors && (
                      <div className="text-danger" style={{ fontSize: '0.875em' }}>
                        {errors.start}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>Data destinazione:</Form.Label>
                  <span> *</span>
                  <div>
                    <DatePicker
                      style={{ lineHeight: 1.7 }}
                      className={`form-control ${validated && 'end' in errors ? 'border border-danger' : ''}`}
                      selected={parsedEndAt}
                      dateFormat="yyyy-MM-dd"
                      locale={localeIt}
                      minDate={parsedStartAt}
                      onChange={this.handleDateChange('end')}
                      isClearable
                      showYearDropdown
                      yearDropdownItemNumber={20}
                      scrollableYearDropdown
                    />
                    {'end' in errors && (
                      <div className="text-danger" style={{ fontSize: '0.875em' }}>
                        {errors.end}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>
            <div>
              <b>NB: </b>La data di destinazione verra' completamente sovrascritta, tutti i dati presenti saranno
              cancellati.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancel} variant="secondary">
            Annulla
          </Button>
          <Button onClick={this.handleConfirm} variant="primary">
            Clona
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect(mstp)(PlannerForm)
