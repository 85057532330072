import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from 'components/dashboard'
import styles from './main.module.css'
import Users from 'components/users'
import DashboardUser from 'components/users/dashboard'
import Cicles from 'components/cicles'
import Subscriptions from 'components/subscriptions'
import Places from 'components/places'
import Trainings from 'components/trainings'
import Reservations from 'components/reservations'
import Causes from 'components/causes'
import Topics from 'components/topics'
import Payments from 'components/payments'
import Levels from 'components/levels'
import RecoveryRequests from 'components/recovery_requests'
import Loading from 'components/system_wide/loading'

class Main extends React.Component {
  render() {
    return (
      <main className={styles.mainPanel}>
        <Loading />
        <Routes>
          <Route path="*" element={<Dashboard />} />
          <Route path="/users/dashboard/:id" element={<DashboardUser />} />
          <Route path="/users" element={<Users />} />
          <Route path="/cicles/*" element={<Cicles />} />
          <Route path="/subscriptions/*" element={<Subscriptions />} />
          <Route path="/places/*" element={<Places />} />
          <Route path="/trainings/*" element={<Trainings />} />
          <Route path="/reservetions/*" element={<Reservations />} />
          <Route path="/causes/*" element={<Causes />} />
          <Route path="/topics/*" element={<Topics />} />
          <Route path="/levels/*" element={<Levels />} />
          <Route path="/payments/*" element={<Payments />} />
          <Route path="/recovery_requests/*" element={<RecoveryRequests />} />
        </Routes>
      </main>
    )
  }
}

export default Main
