import React from 'react'
import { connect } from 'react-redux'
import withRouter from 'utils/withRouter'
import Sidebar from './sidebar'
import Main from './main'
import { getUserInfo } from 'store/userinfo'

const mstp = state => {
  return { access_token: state.userinfo.access_token }
}
class Layout extends React.Component {
  componentDidMount() {
    if (!this.props.access_token) this.props.router.navigate('/login')
    this.props.dispatch(getUserInfo())
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Sidebar />
        <Main />
      </div>
    )
  }
}

export default connect(mstp)(withRouter(Layout))
